import { IClinic } from '../redux/types/clinic';
import { ICustomer } from '../redux/types/customer';

export const getDoctorAddress = (doctor: ICustomer) => {
  const town = doctor.locationMaster?.localityTown ?? '';
  const city = doctor.cityMaster?.name ?? '';
  const state = doctor.stateMaster?.name ?? '';

  const address = `${town ? `${town}, ` : ''} ${city ? `${city}, ` : ''} ${
    state ? `${state}, ` : ''
  } ${doctor.locationMaster?.pincode ?? ''}`;

  return address;
};

export const getClinicAddress = (clinic: IClinic, useLocation = false) => {
  if (useLocation && clinic.location?.lat && clinic.location?.lng) {
    return `${clinic.location.lat},${clinic.location.lng}`;
  } else {
    const addr1 = clinic?.addressLine1 ?? '';
    const addr2 = clinic?.addressLine2 ?? '';
    const addr3 = clinic?.addressLine3 ?? '';
    const city = clinic?.cityMaster?.name ?? '';
    const state = clinic?.stateMaster?.name ?? '';

    const address = `${addr1 ? `${addr1}, ` : ''}${addr2 ? `${addr2}, ` : ''}${
      addr3 ? `${addr3}, ` : ''
    } ${city ? `${city}, ` : ''} ${state ? `${state}, ` : ''} ${
      clinic?.pincode ?? ''
    }`;

    return address;
  }
};
