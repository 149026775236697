import { Button, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent/CardContent";
import CardHeader from "@mui/material/CardHeader";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { APPOINTMENT_DISPLAY_DATE_FORMAT, Domain } from "../../constants";
import { useGetUserSubscriptionsMutation } from "../../redux/services/user";
import { RootState } from "../../redux/store";
import { ICustomerSubscription } from "../../redux/types/customer";
import { attachTokenToUrl } from "../../utils/common-utils";

export const Subscriptions = () => {
  const [getUserSubscription, { data, isLoading }] =
    useGetUserSubscriptionsMutation();
  const patientSubscription = useSelector(
    (state: RootState) => state.user.decodedUserToken?.subscriptions?.patient
  );
  const user = useSelector((state: RootState) => state.user.userDetails);

  useEffect(() => {
    if (patientSubscription && user?.id) {
      getUserSubscription(user.id);
    }
  }, [getUserSubscription, patientSubscription, user]);

  const handleBuySubscriptionClick = () => {
    window.location.href = attachTokenToUrl(
      Domain.ACCOUNT,
      `/subscriptions/patient?callbackUrl=${window.location.origin}${window.location.pathname}`
    );
  };

  return patientSubscription && (data?.length || isLoading) ? (
    <div className="flex flex-wrap overflow-auto gap-4 p-4">
      {data?.map((s: ICustomerSubscription) => (
        <Card className="!rounded-[21px]">
          <CardHeader
            title={s.packageName}
            className="bg-secondary"
            subheader={s.packageMaster?.subTitle ?? ""}
          />
          <CardContent>
            <Typography>{`Valid from ${moment(s.validFrom).format(
              APPOINTMENT_DISPLAY_DATE_FORMAT
            )} to ${moment(s.validTill).format(
              APPOINTMENT_DISPLAY_DATE_FORMAT
            )}`}</Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  ) : (
    <div className="flex flex-col w-full h-full items-center justify-center gap-8 p-8">
      <Typography variant="h5" className="text-center !font-bold">
        You have not yet bought any subscription
      </Typography>
      <Typography className="text-center">
        Choose from the different packages based on your requirements and needs
        such as corporate, family, individual or free*.
      </Typography>
      <Typography className="text-center">
        With subscription, you can do partial reimbursements upto 3 Lakhs. You
        can also choose to buy free subscription but then you will loose the
        reimbursement benefits.
      </Typography>
      <Button variant="contained" onClick={handleBuySubscriptionClick}>
        Click here to view and buy subscription
      </Button>
    </div>
  );
};
