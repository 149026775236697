export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  secondary: '#E8F7FD',
  primary: '#9CC05B',
  primaryLight: '#E8F7FD',
  infoPrimary: '#70C1EA',
  gray: {
    gray05: '#8A8EA6',
    gray10: '#ECEEEE',
    gray20: '#DFE1E2',
    gray30: '#AFB4B7',
    gray40: '#838A8F',
    gray50: '#727272',
    gray60: '#394146',
    gray70: '#404040',
    gray80: '#333333',
    gray90: '#262626',
    gray100: '#1A1A1A',
  },
};
