import { PaletteOptions } from '@mui/material/styles/createPalette';
import { colors } from './colors';

export const palette: PaletteOptions = {
  grey: {
    50: colors.gray.gray05,
    100: colors.gray.gray10,
    200: colors.gray.gray20,
    300: colors.gray.gray30,
    400: colors.gray.gray40,
    500: colors.gray.gray50,
    600: colors.gray.gray60,
    700: colors.gray.gray70,
    800: colors.gray.gray80,
    900: colors.gray.gray90,
    A100: colors.gray.gray100,
  },
  background: {
    default: colors.white,
    paper: colors.white,
  },
  primary: {
    main: colors.primary,
    light: colors.primaryLight,
  },
  secondary: {
    main: colors.secondary,
  },
  info: {
    main: colors.infoPrimary,
  },
  common: {
    black: colors.black,
    white: colors.white,
  },
  text: {
    primary: '#595E80',
    secondary: '#526B8A',
  },
};
