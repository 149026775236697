import { AutoDelete, FileOpen, Update } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

export type CardActionsProps = {
  onlyDisplayView?: boolean;
  onView: () => void;
  onReschedule?: () => void;
  onCancel?: () => void;
};

export const CardActions: React.FC<CardActionsProps> = ({
  onlyDisplayView,
  onView,
  onReschedule,
  onCancel,
}) => {
  return (
    <div className="flex p-1 gap-1">
      <Tooltip title="View Appointment Details">
        <IconButton onClick={onView}>
          <FileOpen color="primary" />
        </IconButton>
      </Tooltip>
      {!onlyDisplayView && (
        <>
          <Tooltip title="Reschedule Appointment">
            <IconButton onClick={onReschedule}>
              <Update color="info" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel Appointment">
            <IconButton onClick={onCancel}>
              <AutoDelete color="warning" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </div>
  );
};
