import React from 'react';
import { DialogContext } from './DialogProvider';

const throwNoContextError = () => {
  throw new Error('context not found');
};

const useDialog = () => {
  const context = React.useContext(DialogContext);

  return context || throwNoContextError();
};

export { useDialog };
