import { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { DialogProps } from './types';

export const DialogManager = forwardRef((_, ref) => {
  const [dialog, setDialog] = useState<DialogProps | null>(null);
  const [open, setOpen] = useState(false);

  const handleDisplayDialog = (dialogProps: DialogProps) => {
    setOpen(true);
    setDialog(dialogProps);
  };

  const handleAccept = () => {
    handleClose();
    dialog?.onAccept?.();
  };

  const handleClose = () => {
    dialog?.onCancel?.();
    setOpen(false);
    setDialog(null);
  };

  const handleUpdateProps = (props: DialogProps) => {
    setDialog({
      ...dialog,
      ...props,
    });
  };

  useImperativeHandle(ref, () => ({
    displayDialog: handleDisplayDialog,
    closeDialog: handleClose,
    updateDialog: handleUpdateProps,
  }));

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{dialog?.title}</DialogTitle>
      <DialogContent>{dialog?.content}</DialogContent>
      <DialogActions>
        {dialog?.cancelBtnTxt && (
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        )}

        <Button variant="contained" onClick={handleAccept}>
          {dialog?.submitBtnTxt ?? 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
