import React, { useMemo, useRef } from 'react';
import { ArrowBack, Cancel, GppBad, Print } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATHS } from '../../../../routes';
window.Buffer = window.Buffer || require('buffer').Buffer;

type Message = {
  invoiceId: string;
  message: string;
  transactionId: string;
};

export const InvoicePaymentFailure: React.FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const info = searchParams.get('info');
  const message = useMemo(() => {
    if (info) {
      const msgString = Buffer.from(info, 'base64').toString('utf-8');

      try {
        return JSON.parse(msgString) as Message;
      } catch (e) {
        return null;
      }
    }

    return null;
  }, [info]);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const handleBack = () => {
    navigate(PATHS.INVOICES, { replace: true });
  };

  return (
    <div ref={ref} className="flex flex-col gap-4 p-4 relative w-full h-full">
      <header className="flex flex-col gap-4 p-2">
        <div className="flex w-full justify-between px-4">
          <IconButton onClick={handleBack}>
            <ArrowBack color="primary" />
          </IconButton>
          <div className="flex-1 flex justify-center gap-2">
            <Cancel fontSize="large" color="error" />
            <Typography variant="h6">Payment Failure</Typography>
          </div>
          <div className="flex gap-4">
            <IconButton onClick={handlePrint}>
              <Print />
            </IconButton>
          </div>
        </div>
      </header>
      <div className="flex p-4 w-full h-full items-center justify-center">
        {message ? (
          <div className="flex flex-col w-full gap-5 bg-red-200 p-4 rounded-md justify-center">
            <div className="flex gap-4 items-center">
              <GppBad color="error" sx={{ width: 30, height: 30 }} />
              <Typography variant="h5" color="error">
                {message.message}
              </Typography>
            </div>
            <div className="flex gap-4 items-center">
              <div className="flex flex-col gap-2">
                <Typography variant="h6">Invoice Id</Typography>
                <Typography>{message.invoiceId}</Typography>
              </div>
              <div className="flex flex-col gap-2">
                <Typography variant="h6">Transaction Id</Typography>
                <Typography>{message.transactionId}</Typography>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center p-4 bg-red-200 p-4 rounded-md w-full">
            <Typography className="!text-center">
              Transaction failed due to some issue! Please try again later or
              contact admin if the amount is deducted from your account!
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
