import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../localstorage/token';
import { API_PATH } from './path';

export const locationApi = createApi({
  reducerPath: 'locationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    createLocation: build.mutation({
      query: (body) => {
        delete body.name;

        return {
          url: API_PATH.locationMasters,
          method: 'POST',
          body,
        };
      },
    }),
    updateLocation: build.mutation({
      query: (params) => {
        delete params.name;

        return {
          url: `${API_PATH.locationMasters}/${params.id}`,
          method: 'PATCH',
          body: params,
        };
      },
    }),
    deleteLocation: build.mutation({
      query: (id) => ({
        url: `${API_PATH.locationMasters}/${id}`,
        method: 'DELETE',
      }),
    }),
    getLocations: build.mutation({
      query: () => ({
        url: `${API_PATH.locationMasters}?filter={"order":"name","include":[{"relation":"stateMaster"},"stateMaster",{"relation":"cityMaster"},"cityMaster"]}`,
        method: 'GET',
      }),
    }),
    getLocationsByStateAndCity: build.mutation({
      query: (params) => ({
        url: `${API_PATH.locationMasters}?filter={"order":"name","where":{"and": [{"stateMasterId":{"eq":"${params.stateMasterId}"}}, {"cityMasterId":{"eq":"${params.cityMasterId}"}}]}}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useGetLocationsMutation,
  useUpdateLocationMutation,
  useGetLocationsByStateAndCityMutation,
} = locationApi;
