import React from 'react';
import { CircularProgress } from '@mui/material';
export type LoaderProps = {
  isLoading: boolean;
};

export const Loader: React.FC<LoaderProps> = ({ isLoading }) =>
  isLoading ? (
    <div className="absolute flex justify-center items-center w-full h-full bg-gray-50/[0.5] z-50">
      <CircularProgress />
    </div>
  ) : null;
