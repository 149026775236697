import {
  Avatar,
  Divider,
  Drawer,
  ListItemButton,
  Typography
} from "@mui/material";
import { LOGGED_IN_MENU, MENU, OTHER_TABS } from "../constants";
import moment from "moment";
import { ICustomer } from "../../redux/types/customer";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import { ServicesAllowed } from "../../redux/enums";
import {
  attachTokenToUrl,
  redirectToLogout,
  stringAvatar
} from "../../utils/common-utils";
import { removeToken } from "../../localstorage/token";
import { Domain } from "../../constants";

export type MobileMenuProps = {
  user: ICustomer | null;
  display: boolean;
  onToggleMenu: () => void;
};

export const MobileMenu: React.FC<MobileMenuProps> = ({
  display,
  user,
  onToggleMenu
}) => {
  const router = useNavigate();
  const hasDoctorAccess = useAppSelector((state) => {
    const user = state.user.userDetails;
    const token = state.user.decodedUserToken;

    return (
      user?.servicesAllowed.includes(ServicesAllowed.DOCTOR) ||
      user?.servicesAllowed.includes(ServicesAllowed.CONSULTANT) ||
      user?.hasAppliedFor === ServicesAllowed.DOCTOR ||
      (token?.subscriptions.doctor &&
        moment(token?.subscriptions.doctor).isAfter(moment()))
    );
  });

  const getMenuRedirectClickHandler = (path: string) => () => {
    onToggleMenu();
    global.window.location.href = path;
  };

  const getMenuClickHandler = (path: string) => () => {
    onToggleMenu();
    router(path);
  };

  const handleLogout = () => {
    removeToken();
    redirectToLogout();
  };

  return (
    <Drawer
      PaperProps={{
        className: "!w-[95vw] bg-secondary flex flex-col"
      }}
      open={display}
      onClose={onToggleMenu}
    >
      <div className="flex flex-col p-8 gap-6 text-brandingText overflow-x-hidden">
        {user && (
          <div className="flex flex-col gap-6">
            <div className="flex gap-4 items-center w-full overflow-hidden">
              <Avatar
                src={user?.profilePicUrl ?? ""}
                {...stringAvatar(user?.firstName ?? "", user?.lastName ?? "")}
                className="w-[95px] h-[95px] font-semibold uppercase text-[2.5rem]"
              />
              <div className="flex flex-col gap-1">
                <Typography
                  variant="h6"
                  fontWeight={600}
                  className="!text-profileText capitalize"
                >{`${user.firstName ?? ""} ${user.lastName ?? ""}`}</Typography>
                <div className="flex flex-col flex-1">
                  {user.email && (
                    <Typography
                      variant="caption"
                      className="truncate"
                      fontWeight={600}
                    >
                      {user.email}
                    </Typography>
                  )}
                  {user.phone && (
                    <Typography variant="caption" fontWeight={600}>
                      {user.phone}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
            <Divider className="bg-brandingText" />
          </div>
        )}
        <div className="flex flex-col items-start gap-4 text-gray1">
          {user && (
            <>
              <ListItemButton
                className="w-full"
                onClick={getMenuRedirectClickHandler(
                  attachTokenToUrl(Domain.ACCOUNT, "/account")
                )}
              >
                <Typography fontWeight={600}>My Account</Typography>
              </ListItemButton>
              <ListItemButton
                className="w-full"
                onClick={getMenuRedirectClickHandler(
                  attachTokenToUrl(Domain.ACCOUNT, "/account#my-subscriptions")
                )}
              >
                <Typography fontWeight={600}>My Subscriptions</Typography>
              </ListItemButton>
            </>
          )}
          {MENU.map((tab) =>
            "path" in tab ? (
              <ListItemButton
                key={tab.name}
                className="w-full"
                onClick={getMenuRedirectClickHandler(tab.path)}
              >
                <Typography fontWeight={600}>{tab.name}</Typography>
              </ListItemButton>
            ) : (
              <ListItemButton
                key={tab.name}
                className="w-full"
                onClick={getMenuClickHandler(tab.route)}
              >
                <Typography fontWeight={600}>{tab.name}</Typography>
              </ListItemButton>
            )
          )}
          {OTHER_TABS.map((tab) => (
            <ListItemButton
              key={tab.name}
              className="w-full"
              onClick={getMenuRedirectClickHandler(tab.link)}
            >
              <Typography fontWeight={600}>{tab.name}</Typography>
            </ListItemButton>
          ))}
          {user && (
            <>
              {LOGGED_IN_MENU.map((tab) => (
                <ListItemButton
                  key={tab.name}
                  className="w-full"
                  onClick={getMenuClickHandler(tab.path)}
                >
                  <Typography fontWeight={600}>{tab.name}</Typography>
                </ListItemButton>
              ))}
              {hasDoctorAccess && (
                <ListItemButton
                  className="w-full"
                  onClick={getMenuRedirectClickHandler(
                    process.env.REACT_APP_CPMS_BASE_URL || ""
                  )}
                >
                  <Typography fontWeight={600}>Patient Management</Typography>
                </ListItemButton>
              )}
              <ListItemButton className="w-full" onClick={handleLogout}>
                <Link to="/logout">
                  <Typography fontWeight={600}>Logout</Typography>
                </Link>
              </ListItemButton>
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};
