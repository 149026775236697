import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { ErrorOutline, TaskAlt } from '@mui/icons-material';
import { PaidReimbursements } from './components/PaidReimbursements';
import { PendingReimbursements } from './components/PendingReimbursements';

export const Reimbursement = () => {
  const [value, setValue] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className="flex flex-col w-full h-full overflow-auto text-brandingText">
      <Tabs value={value} onChange={handleChange}>
        <Tab
          value={0}
          label="Upcoming / Unpaid"
          icon={<ErrorOutline />}
          iconPosition="start"
        />
        <Tab value={1} label="Paid" icon={<TaskAlt />} iconPosition="start" />
      </Tabs>
      <div className="flex flex-row gap-4 flex-wrap h-full overflow-auto p-2">
        {value === 1 && <PaidReimbursements />}
        {value === 0 && <PendingReimbursements />}
      </div>
    </div>
  );
};
