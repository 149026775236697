import React, { useEffect, useState } from "react";
import {
  CssBaseline,
  Box,
  Typography,
  IconButton,
  Toolbar,
  AppBar,
  Button
} from "@mui/material";
import { useAppSelector } from "../../redux/store";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { Menu as MenuIcon } from "@mui/icons-material";
import Hidden from "@mui/material/Hidden";
import { LOGGED_IN_MENU, MENU } from "../constants";
import { MobileMenu } from "./mobile-menu";
import { AccountMenu } from "../../components/AccountMenu";
import { ServicesAllowed } from "../../redux/enums";
import moment from "moment";
import { PATHS } from "../../routes";
import { attachTokenToUrl } from "../../utils/common-utils";
import { Domain } from "../../constants";

export const AppLayout = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const user = useAppSelector((state) => state.user.userDetails);
  const navigate = useNavigate();
  const location = useLocation();
  const tabId = `${location.pathname}${location.search}`;

  const hasDoctorAccess = useAppSelector((state) => {
    const user = state.user.userDetails;
    const token = state.user.decodedUserToken;

    return (
      user?.servicesAllowed.includes(ServicesAllowed.DOCTOR) ||
      user?.servicesAllowed.includes(ServicesAllowed.CONSULTANT) ||
      user?.hasAppliedFor === ServicesAllowed.DOCTOR ||
      (token?.subscriptions.doctor &&
        moment(token?.subscriptions.doctor).isAfter(moment()))
    );
  });

  useEffect(() => {
    if (!tabId) {
      navigate(PATHS.HOME);
    }
  }, [navigate, tabId]);

  const renderTabs = (tabs: typeof MENU) => {
    return tabs.map((tab) =>
      "path" in tab ? (
        <Button
          key={tab.name}
          size="small"
          variant="text"
          className="!text-gray2 hover:underline !text-[1rem] normalcase"
          onClick={getMenuRedirectClickHandler(tab.path)}
        >
          {tab.name}
        </Button>
      ) : (
        <Button
          key={tab.name}
          size="small"
          variant="text"
          className="!text-gray2 hover:underline !text-[1rem] normalcase"
          onClick={getMenuClickHandler(tab.route)}
        >
          {tab.name}
        </Button>
      )
    );
  };

  const getMenuRedirectClickHandler = (path: string) => () => {
    window.location.href = path;
  };

  const getMenuClickHandler = (path: string) => () => {
    navigate(path);
  };

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <Box className="w-screen flex flex-col h-screen fixed text-brandingText">
      <CssBaseline />
      <AppBar
        color="transparent"
        position="relative"
        className={`!bg-white top-0 w-full left-0`}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Hidden mdUp>
            <IconButton color="secondary" onClick={handleToggleMenu}>
              <MenuIcon className="!text-footer" />
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            <a href={attachTokenToUrl(Domain.WWW, "/")}>
              <img src={logo} alt="IDDA Logo" width={100} />
            </a>
          </Hidden>
          <Hidden mdDown>
            <div className="flex items-center gap-1 flex-1">
              {renderTabs(MENU.slice(0, 5))}
            </div>
          </Hidden>
          <div className="flex flex-1 justify-end gap-3">
            <Hidden mdDown>
              <div className="flex items-center">
                {renderTabs(MENU.slice(5, 6))}
              </div>
            </Hidden>
            {user?.id ? <AccountMenu user={user} /> : null}
          </div>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <MobileMenu
          user={user}
          display={toggleMenu}
          onToggleMenu={handleToggleMenu}
        />
      </Hidden>
      <Hidden mdDown>
        {user && user.id && (
          <div className="flex justify-center items-center gap-6 bg-patientToolbar text-white p-[6px]">
            {LOGGED_IN_MENU.map((item) => (
              <Typography
                key={item.name}
                fontWeight={600}
                className="cursor-pointer hover:underline"
                onClick={getMenuClickHandler(item.path)}
              >
                {item.name}
              </Typography>
            ))}
            {hasDoctorAccess && (
              <Typography
                fontWeight={600}
                className="cursor-pointer hover:underline"
                onClick={getMenuRedirectClickHandler(
                  process.env.REACT_APP_CPMS_BASE_URL || ""
                )}
              >
                Patient Management
              </Typography>
            )}
          </div>
        )}
      </Hidden>
      <div className="flex flex-1 flex-col w-full p-4 items-center gap-4 overflow-auto">
        {<Outlet />}
      </div>
    </Box>
  );
};
