const ADMIN_TOKEN_KEY = 'admin-token';
const TOKEN_DATE = 'token-date';

export const setToken = (token: string) => {
  localStorage.setItem(ADMIN_TOKEN_KEY, token);
  global.window?.localStorage.setItem(TOKEN_DATE, new Date().toISOString());
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(ADMIN_TOKEN_KEY);

  return token;
};

export const removeToken = () => {
  localStorage.removeItem(ADMIN_TOKEN_KEY);
  global.window?.localStorage.removeItem(TOKEN_DATE);
};

export const getTokenDate = () => {
  const date = global.window?.localStorage.getItem(TOKEN_DATE);

  return date;
};
