import { createSlice } from '@reduxjs/toolkit';
import { ICustomer, UserToken } from './types/customer';

export type InitialState = {
  userDetails: ICustomer;
  decodedUserToken: UserToken;
  buySubscriptionPopupDisplayed: boolean;
  selectedFamilyMember: ICustomer | null;
};

const initialState: InitialState = {
  userDetails: {
    id: '',
    uniqueId: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    servicesAllowed: [],
  },
  decodedUserToken: {
    exp: 0,
    iat: 0,
    id: '',
    isDoctorVerified: false,
    name: '',
    role: '',
    scope: {
      doctor: [],
      patient: [],
    },
    subscriptions: {},
    subscriptionUsers: [],
  },
  buySubscriptionPopupDisplayed: false,
  selectedFamilyMember: null,
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
    setDecodedUserToken: (state, { payload }) => {
      state.decodedUserToken = payload;
    },
    setBuySubscriptionPopupDisplayed: (state, { payload }) => {
      state.buySubscriptionPopupDisplayed = payload;
    },
    setSelectedFamilyMember: (state, { payload }) => {
      state.selectedFamilyMember = payload;
    },
  },
});

export const {
  setUserDetails,
  setDecodedUserToken,
  setBuySubscriptionPopupDisplayed,
  setSelectedFamilyMember,
} = userSlice.actions;
export default userSlice.reducer;
