import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../localstorage/token';
import { API_PATH } from './path';

export const PAGE_LIMIT = 30;

export const appointmentsApi = createApi({
  reducerPath: 'appointments',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    createPatientAppointments: build.mutation({
      query: (body) => ({
        url: `/appointments`,
        method: 'POST',
        body,
      }),
    }),
    updatePatientAppointment: build.mutation({
      query: (body) => ({
        url: `/appointments/${body.id}`,
        method: 'PATCH',
        body,
      }),
    }),
    getPatientAppointmentsCount: build.mutation({
      query: (patientId) => ({
        url: `${API_PATH.appointments}/count?"where"={"isActive":"true","patientId":"${patientId}"}`,
        method: 'GET',
      }),
    }),
    getPatientAppointments: build.mutation({
      query: ({ offset, patientId }) => ({
        url: `${API_PATH.appointments}/?filter={"limit":${PAGE_LIMIT},"offset":"${offset}","where":{"isActive":"true","patientId":"${patientId}"},"order":"startTime DESC","include":[{"relation":"patient","scope":{"fields":["id","firstName","lastName","email","phone"],"where":{"isActive":"true"}}},{"relation":"doctor","scope":{"fields":["firstName","lastName","slug","id","avgRating","profilePicUrl","gender","designation"],"where":{"isActive":"true"},"include":[{"relation":"doctorProfile","scope":{"fields":["isAvailableForChat"]}}]}},{"relation":"clinic", "scope":{"fields":["id","name","slug","clinicLogoUrl","addressLine1","addressLine2","addressLine3","localityTown","pincode","location","contactName","contactPhone","contactEmail","clinicPics"],"where":{"isActive":"true"}},"include": [{"relation":"city","scope":{"fields":["id","name"],"where":{"isActive":"true"}}}]}]}`,
        method: 'GET',
      }),
    }),
    getPatientAppointmentDetails: build.mutation({
      query: (id) => ({
        url: `${API_PATH.appointments}/?filter={"where":{"id":"${id}"},"include":[{"relation":"patient"},{"relation":"doctor"},{"relation":"clinic", "include": [{"relation":"city"}]},{"relation": "appointmentClinicalNotes","scope":{"where":{"isActive":"true"},"include":[{"relation": "doctor"}]}},{"relation": "appointmentClinicalReports","scope":{"where":{"isActive":"true"},"include":[{"relation": "doctor"}]}},{"relation": "appointmentPrescribedMedicines","scope":{"where":{"isActive":"true"},"include":[{"relation": "doctor"}]}},{"relation": "appointmentTreatmentOffereds","scope":{"where":{"isActive":"true", "showToPatient":"true"},"include":[{"relation": "doctor"}]}},{"relation": "appointmentActualProcedures","scope":{"where":{"isActive":"true"},"include":[{"relation": "doctor"}]}},{"relation": "appointmentPaymentDetails"}]}`,
        method: 'GET',
      }),
    }),
    cancelAppointment: build.mutation({
      query: (id) => ({
        url: `appointments/${id}/cancel`,
        method: 'PATCH',
      }),
    }),
    getAppointmentInvoices: build.mutation({
      query: ({ paymentReceived, offset, patientId }) => ({
        url: `${API_PATH.appointmentInvoices}/?filter={"limit":${PAGE_LIMIT},"offset":"${offset}","order":"paymentDate DESC","where":{"isActive":"true","patientId":"${patientId}","paymentReceived":"${paymentReceived}"},"include":[{"relation":"patient","scope":{"where":{"isActive":"true"},"fields":["id","firstName","lastName","profilePicUrl"]}},{"relation":"doctor","scope":{"fields":["id","firstName","lastName","profilePicUrl"]}},{"relation":"clinic","scope":{"where":{"isActive":"true"},"fields":["id","name"]}},{"relation":"appointment","scope":{"where":{"isActive":"true"},"fields":["id","doctorId","startTime","endTime","appointmentType","appointmentStatus","clinicId","patientId"]}}]}`,
        method: 'GET',
      }),
    }),
    getAppointmentInvoiceDetails: build.mutation({
      query: ({ id }) => ({
        url: `${API_PATH.appointmentInvoices}/?filter={"where":{"isActive":"true","id":"${id}"},"include":[{"relation":"patient","scope":{"where":{"isActive":"true"},"fields":["id","firstName","lastName","profilePicUrl"]}},{"relation":"doctor","scope":{"fields":["id","firstName","lastName","profilePicUrl"]}},{"relation":"clinic","scope":{"where":{"isActive":"true"},"fields":["id","name"]}},{"relation":"appointment","scope":{"where":{"isActive":"true"},"fields":["id","doctorId","startTime","endTime","appointmentType","appointmentStatus","clinicId","patientId"]}}]}`,
        method: 'GET',
      }),
    }),
    getAppointmentInvoicesCount: build.mutation({
      query: ({ patientId, paymentReceived }) => ({
        url: `${API_PATH.appointmentInvoices}/count?where={"isActive":"true","patientId":"${patientId}","paymentReceived":"${paymentReceived}"}`,
        method: 'GET',
      }),
    }),
    getAppointmentUnpaidReimbursements: build.mutation({
      query: ({ offset }) => ({
        url: `${API_PATH.appointmentReimbursements}/?filter={"limit":${PAGE_LIMIT},"where":{"reimbursementPaid":"0"},"offset":"${offset}","order":"reimbursementDateForPatient DESC","include":[{"relation":"patient","include":[{"scope":{"fields":{"firstName":"true", "lastName":"true"}}}]},{"relation":"doctor","include":[{"scope":{"fields":{"firstName":"true", "lastName":"true"}}}]},{"relation":"clinic","scope":{"where":{"isActive":"true"},"fields":["id","name"]}}]}`,
        method: 'GET',
      }),
    }),
    getAppointmentPaidReimbursements: build.mutation({
      query: ({ offset, today }) => ({
        url: `${API_PATH.appointmentReimbursements}/?filter={"limit":${PAGE_LIMIT},"offset":"${offset}","order":"paymentReceivedDate DESC","where":{"reimbursementPaid": {"gt":"0"}},"include":[{"relation":"patient","include":[{"scope":{"fields":{"firstName":"true", "lastName":"true"}}}]},{"relation":"doctor","include":[{"scope":{"fields":{"firstName":"true", "lastName":"true"}}}]},{"relation":"clinic","scope":{"where":{"isActive":"true"},"fields":["id","name"]}}]}`,
        method: 'GET',
      }),
    }),
    getAppointmentActualProcedures: build.mutation({
      query: ({ offset, patientId }) => ({
        url: `${API_PATH.appointmentActualProcedures}/?filter={"limit":${PAGE_LIMIT},"offset":"${offset}","where":{"isActive":"true","patientId":"${patientId}","paymentStatus":{"inq": ["pending","partial"]}},"include":[{"relation":"patient","scope":{"fields":["firstName","lastName"]}},{"relation":"doctor","scope":{"fields":["firstName","lastName"]}},{"relation":"clinic","scope":{"where":{"isActive":"true"},"fields":["id","name"]}},{"relation":"appointment","scope":{"where":{"isActive":"true"},"fields":["id","doctorId","startTime","endTime","appointmentType","appointmentStatus","clinicId","patientId"]}}]}`,
        method: 'GET',
      }),
    }),
    getAppointmentActualProceduresByProcedureIds: build.mutation({
      query: ({ patientId, procedureIds }) => ({
        url: `${API_PATH.appointmentActualProcedures}/?filter={"where":{"isActive":"true","id":{"inq": [${procedureIds}]},"patientId":"${patientId}"},"include":[{"relation":"patient","scope":{"fields":["firstName","lastName"]}},{"relation":"doctor","scope":{"fields":["firstName","lastName"]}},{"relation":"clinic","scope":{"where":{"isActive":"true"},"fields":["id","name"]}},{"relation":"appointment","scope":{"where":{"isActive":"true"},"fields":["id","doctorId","startTime","endTime","appointmentType","appointmentStatus","clinicId","patientId"]}}]}`,
        method: 'GET',
      }),
    }),
    initiatePayment: build.mutation({
      query: ({ body, id }) => ({
        url: `${API_PATH.appointmentConsultationPrePayments}/${id}/initiate-online-payment`,
        method: 'POST',
        body,
      }),
    }),
    initiateInvoicePayment: build.mutation({
      query: ({ body, id }) => ({
        url: `${API_PATH.appointmentInvoices}/${id}/initiate-online-payment`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetPatientAppointmentsCountMutation,
  useGetPatientAppointmentsMutation,
  useCreatePatientAppointmentsMutation,
  useGetPatientAppointmentDetailsMutation,
  useUpdatePatientAppointmentMutation,
  useCancelAppointmentMutation,
  useGetAppointmentInvoicesMutation,
  useGetAppointmentInvoicesCountMutation,
  useGetAppointmentPaidReimbursementsMutation,
  useGetAppointmentUnpaidReimbursementsMutation,
  useGetAppointmentActualProceduresMutation,
  useGetAppointmentActualProceduresByProcedureIdsMutation,
  useGetAppointmentInvoiceDetailsMutation,
  useInitiateInvoicePaymentMutation,
  useInitiatePaymentMutation,
} = appointmentsApi;
