import { useCallback, useEffect, useState } from 'react';
import {
  PAGE_LIMIT,
  useGetAppointmentInvoicesCountMutation,
  useGetAppointmentInvoicesMutation,
} from '../../../../redux/services/appointments';
import { Loader } from '../../../../components/Loader';
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  TablePagination,
  Typography,
} from '@mui/material';
import { AppointmentPaymentDetails } from '../../../../redux/types/appointment';
import moment from 'moment';
import { APPOINTMENT_DISPLAY_DATE_FORMAT } from '../../../../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { stringAvatar } from '../../../../utils/common-utils';
import { InvoiceDetails } from '../../../../Drawers/InvoiceDetails';

export const PaidInvoices = () => {
  const [page, setPage] = useState(0);
  const [invoiceHtml, setInvoiceHtml] = useState('');
  const [getAppointmentInvoices, { isLoading, data }] =
    useGetAppointmentInvoicesMutation();
  const [getAppointmentInvoicesCount, { data: countData }] =
    useGetAppointmentInvoicesCountMutation();
  const patientId = useSelector((state: RootState) => {
    const { userDetails, selectedFamilyMember } = state.user;

    if (selectedFamilyMember) {
      return selectedFamilyMember.id;
    }

    return userDetails?.id ?? '';
  });

  const fetchAppointmentInvoices = useCallback(
    (offset = 0) => {
      getAppointmentInvoices({ offset, paymentReceived: true, patientId });
    },
    [patientId, getAppointmentInvoices],
  );

  useEffect(() => {
    if (patientId) {
      fetchAppointmentInvoices();
      getAppointmentInvoicesCount({ patientId, paymentReceived: true });
    }
  }, [patientId, fetchAppointmentInvoices, getAppointmentInvoicesCount]);

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    value: number,
  ) => {
    setPage(value);
    fetchAppointmentInvoices(PAGE_LIMIT * value);
  };

  const handleCloseinvoice = () => {
    setInvoiceHtml('');
  };

  const getInvoiceClickHandler = (invoiceHtml?: string) => () => {
    setInvoiceHtml(
      invoiceHtml ||
        '<p>No information available yet. Please visit this section again.</p>',
    );
  };

  return (
    <div className="flex flex-col w-full h-full overflow-auto gap-2 relative">
      <Loader isLoading={isLoading} />
      <div className="flex flex-wrap flex-1 overflow-auto w-full gap-4 content-start p-2">
        {data?.length > 0
          ? data.map((item: AppointmentPaymentDetails) => (
              <Card key={item.transactionId} className="w-full md:w-fit">
                <CardHeader
                  className="bg-primary text-white"
                  avatar={
                    <Avatar
                      {...stringAvatar(
                        item.doctor?.firstName ?? '',
                        item.doctor?.lastName ?? '',
                      )}
                      src={item.doctor?.profilePicUrl ?? ''}
                    />
                  }
                  title={`Dr. ${item.doctor?.firstName ?? '-'} ${
                    item.doctor?.lastName ?? '-'
                  }`}
                  titleTypographyProps={{
                    fontWeight: 600,
                    variant: 'subtitle1',
                  }}
                  subheader={item.clinic?.name ?? '-'}
                  subheaderTypographyProps={{ color: 'white' }}
                />
                <CardContent>
                  <div className="flex gap-2 mb-2">
                    <Typography fontSize={14}>Patient Name: </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                    >{`${item.patient?.firstName} ${item.patient?.lastName}`}</Typography>
                  </div>
                  <Divider />
                  <div className="flex gap-2 mt-2">
                    <Typography fontSize={14}>Payment Date : </Typography>
                    <Typography fontSize={14} fontWeight={600}>
                      {moment(item.paymentDate).format(
                        APPOINTMENT_DISPLAY_DATE_FORMAT,
                      )}
                    </Typography>
                  </div>
                  <div className="flex gap-2 mt-2">
                    <Typography fontSize={14}>Amount Paid : </Typography>
                    <Typography fontSize={14} fontWeight={600}>
                      ₹ {item.amount?.toFixed(2)}/-
                    </Typography>
                  </div>
                  <div className="flex gap-2 mt-2">
                    <Typography fontSize={14}>Payment Mode : </Typography>
                    <Typography
                      className="!capitalize"
                      fontSize={14}
                      fontWeight={600}
                    >
                      {item?.paymentMode?.replace(/_/g, ' ')}
                    </Typography>
                  </div>
                </CardContent>
                <CardActionArea
                  color="info"
                  className="!p-2 text-primary"
                  onClick={getInvoiceClickHandler(item.invoiceHtml)}
                >
                  <Typography variant="button" fontWeight={600}>
                    View Invoice
                  </Typography>
                </CardActionArea>
              </Card>
            ))
          : !isLoading && (
              <div className="flex w-full h-full flex-1 justify-center items-center">
                <Typography>
                  No Invoice generated yet. Once doctor generates the invoice,
                  you will be able to view it here.
                </Typography>
              </div>
            )}
      </div>
      <div className="flex w-full gap-4 justify-between">
        {countData?.count > PAGE_LIMIT ? (
          <TablePagination
            component="div"
            count={countData.count}
            page={page}
            color="primary"
            rowsPerPage={PAGE_LIMIT}
            rowsPerPageOptions={[]}
            onPageChange={handlePageChange}
          />
        ) : (
          <div className="flex-1" />
        )}
      </div>
      {invoiceHtml && (
        <InvoiceDetails
          invoiceHtml={invoiceHtml}
          onClose={handleCloseinvoice}
        />
      )}
    </div>
  );
};
