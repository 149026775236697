import { useState, useEffect } from 'react';
import {
  Check,
  Phone,
  LocationOn,
  CurrencyRupee,
  ReceiptLong,
  ArrowForwardIos,
} from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Typography,
  List,
  Backdrop,
  CircularProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { stringAvatar } from '../../utils/common-utils';
import { Loader } from '../../components/Loader';
import ListItem from '@mui/material/ListItem/ListItem';
import moment from 'moment';
import { APPOINTMENT_DISPLAY_DATE_FORMAT } from '../../constants';
import { colors } from '../../themes/colors';
import { useGetUserSubscriptionsMutation } from '../../redux/services/user';
import { InvoiceDetails } from '../../Drawers/InvoiceDetails';
import { ICustomerSubscription } from '../../redux/types/customer';

export const Account = () => {
  const user = useSelector((state: RootState) => state.user.userDetails ?? {});
  const [getSubscriptionInvoices, { data: invoices, isLoading }] =
    useGetUserSubscriptionsMutation();
  const [invoiceHtml, setInvoiceHtml] = useState('');

  useEffect(() => {
    if (user.id && !invoices) {
      getSubscriptionInvoices(user.id);
    }
  }, [getSubscriptionInvoices, invoices, user]);

  const handleCloseinvoice = () => {
    setInvoiceHtml('');
  };

  const getInvoiceClickHandler = (invoiceHtml: string) => () => {
    setInvoiceHtml(invoiceHtml);
  };

  return (
    <div className="flex flex-col w-full h-full gap-4 overflow-auto relative items-center p-4">
      {!user.id && <Loader isLoading />}

      <Card className="!overflow-visible w-full md:w-2/4">
        <CardHeader
          title="Basic Information"
          avatar={<Avatar sx={{ bgcolor: colors.primary }} />}
          titleTypographyProps={{ variant: 'h6' }}
          subheader="Your basic information"
        />
        <CardContent>
          <List>
            {user?.profilePicUrl && (
              <ListItem divider className="flex w-full !justify-between">
                <Typography>Photo</Typography>
                <Avatar
                  src={user?.profilePicUrl ?? ''}
                  {...stringAvatar(user?.firstName ?? '', user?.lastName ?? '')}
                />
              </ListItem>
            )}

            <ListItem divider className="flex w-full !justify-between">
              <Typography>Name</Typography>
              <Typography>
                {`${user?.firstName ?? '-'} ${user?.lastName ?? '-'}`}
              </Typography>
            </ListItem>
            <ListItem divider className="flex w-full !justify-between">
              <Typography>Unique Id</Typography>
              <Typography>{user.uniqueId}</Typography>
            </ListItem>
            {user.designation && (
              <ListItem divider className="flex w-full !justify-between">
                <Typography className="flex-1">Designation</Typography>
                <Typography className="flex-1 text-right">
                  {user.designation}
                </Typography>
              </ListItem>
            )}
            {user.aadharNo && (
              <ListItem divider className="flex w-full !justify-between">
                <Typography>Adhar Number</Typography>
                <Typography>{user.aadharNo}</Typography>
              </ListItem>
            )}
            {user?.dob && (
              <ListItem divider className="flex w-full !justify-between">
                <Typography>Birthday</Typography>
                <Typography>
                  {user?.dob
                    ? moment(user?.dob).format(APPOINTMENT_DISPLAY_DATE_FORMAT)
                    : '-'}
                </Typography>
              </ListItem>
            )}
            <ListItem divider className="flex w-full !justify-between">
              <Typography>Gender</Typography>
              <Typography>{user?.gender ?? '-'}</Typography>
            </ListItem>
            <ListItem divider className="flex w-full !justify-between">
              <Typography>Blood Group</Typography>
              <Typography>{user?.bloodGroup ?? '-'}</Typography>
            </ListItem>
            <ListItem className="flex w-full !justify-between">
              <Typography>Company Name</Typography>
              <Typography>{user?.companyName ?? '-'}</Typography>
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Card className="!overflow-visible w-full md:w-2/4">
        <CardHeader
          title="Balance Information"
          avatar={
            <Avatar sx={{ bgcolor: colors.primary }}>
              <CurrencyRupee />
            </Avatar>
          }
          titleTypographyProps={{ variant: 'h6' }}
          subheader="Your balance information"
        />
        <CardContent>
          <List>
            <ListItem divider className="flex w-full !justify-between">
              <Typography>Balance Amount</Typography>
              <div className="flex gap-2">
                <Typography>{`₹ ${user?.balanceAmount ?? '0'}`}</Typography>
              </div>
            </ListItem>
            <ListItem className="flex w-full !justify-between">
              <Typography>Spent Amount</Typography>
              <div className="flex gap-2">
                <Typography>{`₹  ${user?.spentAmount ?? '0'}`}</Typography>
              </div>
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Card className="!overflow-visible w-full md:w-2/4">
        <CardHeader
          title="Contact Information"
          avatar={
            <Avatar sx={{ bgcolor: colors.primary }}>
              <Phone />
            </Avatar>
          }
          titleTypographyProps={{ variant: 'h6' }}
          subheader="Your contact information"
        />
        <CardContent>
          <List>
            <ListItem divider className="flex w-full !justify-between">
              <Typography>Phone</Typography>
              <div className="flex gap-2">
                <Typography>{user?.phone ?? '-'}</Typography>
                {user?.isPhoneValidated && <Check color="success" />}
              </div>
            </ListItem>
            <ListItem className="flex w-full !justify-between">
              <Typography>Email</Typography>
              <div className="flex gap-2">
                <Typography>{user?.email ?? '-'}</Typography>
                {user?.isEmailValidated && <Check color="success" />}
              </div>
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Card className="!overflow-visible w-full md:w-2/4">
        <CardHeader
          title="Location Information"
          avatar={
            <Avatar sx={{ bgcolor: colors.primary }}>
              <LocationOn />
            </Avatar>
          }
          titleTypographyProps={{ variant: 'h6' }}
          subheader="Your location details"
        />
        <CardContent>
          <ListItem divider className="flex w-full !justify-between">
            <Typography>Locality Town</Typography>
            <Typography>{user?.locationMaster?.localityTown ?? '-'}</Typography>
          </ListItem>
          <ListItem divider className="flex w-full !justify-between">
            <Typography>City</Typography>
            <Typography>{user?.cityMaster?.name ?? '-'}</Typography>
          </ListItem>
          <ListItem className="flex w-full !justify-between">
            <Typography>State</Typography>
            <Typography>{user?.stateMaster?.name ?? '-'}</Typography>
          </ListItem>
        </CardContent>
      </Card>
      <Card className="!overflow-visible w-full md:w-2/4">
        <CardHeader
          title="Subscription Invoices"
          avatar={
            <Avatar sx={{ bgcolor: colors.primary }}>
              <ReceiptLong />
            </Avatar>
          }
          titleTypographyProps={{ variant: 'h6' }}
          subheader="Your subscription invoices"
        />
        <CardContent>
          {invoices?.map((s: ICustomerSubscription) => (
            <ListItem
              key={s.invoiceNo}
              onClick={getInvoiceClickHandler(s.invoiceHtml)}
              divider
            >
              <ListItemButton>
                <ListItemIcon>
                  <ReceiptLong color="primary" />
                </ListItemIcon>
                <div className="flex flex-col">
                  <ListItemText>{s.invoiceNo}</ListItemText>
                  <Typography variant="subtitle2">
                    {s.packageMaster?.name ?? '-'}
                  </Typography>
                </div>
                <ListItemSecondaryAction>
                  <ArrowForwardIos />
                </ListItemSecondaryAction>
              </ListItemButton>
            </ListItem>
          ))}
        </CardContent>
      </Card>
      {invoiceHtml && (
        <InvoiceDetails
          invoiceHtml={invoiceHtml}
          onClose={handleCloseinvoice}
        />
      )}
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
};
