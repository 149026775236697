import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { PaidInvoices } from './components/PaidInvoices';
import { PendingPayments } from './components/PendingPayments/PendingPayments';
import { PaymentInvoice } from './enum';
import { ErrorOutline, TaskAlt } from '@mui/icons-material';

export const Invoices = () => {
  const [value, setValue] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className="flex flex-col w-full h-full overflow-auto">
      <Tabs value={value} onChange={handleChange}>
        <Tab
          value={PaymentInvoice.PendingPayments}
          label="Pending Payments"
          icon={<ErrorOutline />}
          iconPosition="start"
        />
        <Tab
          value={PaymentInvoice.PaidInvoices}
          label="Paid Invoices"
          icon={<TaskAlt />}
          iconPosition="start"
        />
      </Tabs>
      <div className="flex flex-row gap-4 flex-wrap h-full overflow-auto p-2">
        {value === PaymentInvoice.PendingPayments && <PendingPayments />}
        {value === PaymentInvoice.PaidInvoices && <PaidInvoices />}
      </div>
    </div>
  );
};
