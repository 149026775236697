export const API_PATH = {
  userDetails: '/users/me',
  mediaUpload: '/media/upload',
  locationMasters: '/location-masters',
  appointments: '/patient/appointments',
  customerController: '/customers',
  doctor: '/doctor',
  subscriptions: '/customer-subscriptions',
  familyMembers: '/users/family',
  logout: 'users/logout',
  serviceMasters: '/service-masters',
  appointmentInvoices: '/appointment-payment-details',
  appointmentActualProcedures: '/appointment-actual-procedures',
  appointmentConsultationPrePayments: 'appointment-consultation-pre-payments',
  appointmentReimbursements: '/doctor-patient-reimbursements/patient',
};
