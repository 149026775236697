import React, { useEffect, useMemo, useRef } from 'react';
import { ArrowBack, CheckCircleOutline, Print } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetAppointmentInvoiceDetailsMutation } from '../../../../redux/services/appointments';
import { Loader } from '../../../../components/Loader';
import { PATHS } from '../../../../routes';

type Message = {
  invoiceId: string;
  message: string;
  transactionId: string;
};

export const InvoicePaymentSuccess: React.FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { id } = useParams();
  const [fetchAppointmentInvoices, { data, isLoading }] =
    useGetAppointmentInvoiceDetailsMutation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const info = searchParams.get('info');
  const message = useMemo(() => {
    if (info) {
      const msgString = Buffer.from(info, 'base64').toString('utf-8');

      try {
        return JSON.parse(msgString) as Message;
      } catch (e) {
        return null;
      }
    }

    return null;
  }, [info]);

  useEffect(() => {
    if (id) {
      fetchAppointmentInvoices({ id });
    }
  }, [fetchAppointmentInvoices, id]);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const handleBack = () => {
    navigate(PATHS.INVOICES, { replace: true });
  };

  return (
    <div className="flex flex-col gap-2 relative w-full h-full p-4">
      <header className="flex flex-col gap-4 p-2">
        <div className="flex w-full justify-between px-4">
          <IconButton onClick={handleBack}>
            <ArrowBack />
          </IconButton>
          <div className="flex-1 flex justify-center gap-2">
            <CheckCircleOutline fontSize="large" color="success" />
            <Typography variant="h5">Payment Success</Typography>
          </div>
          {!!data?.[0]?.invoiceHtml && (
            <div className="flex gap-4">
              <IconButton onClick={handlePrint}>
                <Print />
              </IconButton>
            </div>
          )}
        </div>
      </header>
      <Loader isLoading={isLoading} />
      {data?.[0]?.invoiceHtml ? (
        <div
          ref={ref}
          className="flex flex-col w-full gap-4 overflow-auto w-full h-full"
          dangerouslySetInnerHTML={{ __html: data?.[0]?.invoiceHtml }}
        />
      ) : (
        <div className="p-4 flex-col flex items-center gap-5">
          {message ? (
            <div className="flex flex-col w-fit gap-5 bg-green-200 p-8 rounded-md justify-center">
              <div className="flex gap-4 items-center">
                <CheckCircleOutline
                  color="success"
                  sx={{ width: 30, height: 30 }}
                />
                <Typography variant="h5" className="text-green-700">
                  {message.message}
                </Typography>
              </div>
              <div className="flex gap-4 items-center">
                <div className="flex flex-col gap-2">
                  <Typography variant="h6">Invoice Id</Typography>
                  <Typography>{message.invoiceId}</Typography>
                </div>
                <div className="flex flex-col gap-2">
                  <Typography variant="h6">Transaction Id</Typography>
                  <Typography>{message.transactionId}</Typography>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center p-4 bg-red-200 p-4 rounded-md w-full">
              <Typography className="!text-center">
                Transaction failed due to some issue! Please try again later or
                contact admin if the amount is deducted from your account!
              </Typography>
            </div>
          )}
          <Button variant="contained" className="!w-fit" onClick={handleBack}>
            Go Back
          </Button>
        </div>
      )}
    </div>
  );
};
