import { useEffect, useMemo, useRef } from 'react';
import { Typography, Avatar, Divider, Button, IconButton } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetPatientAppointmentDetailsMutation } from '../../redux/services/appointments';
import { Loader } from '../../components/Loader';
import {
  Check,
  CheckCircle,
  Close,
  Event,
  GppBad,
  Print,
  ReportGmailerrorred,
  Schedule,
} from '@mui/icons-material';
import moment from 'moment';
import {
  APPOINTMENT_DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
} from '../../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getClinicAddress } from '../../utils/doctor-utils';
import { useGetDoctorClinicDetailsMutation } from '../../redux/services/doctors';
import { calculateAge } from '../../Drawers/ViewAppointmentDetails/utils';
import { useReactToPrint } from 'react-to-print';
import { PATHS } from '../../routes';

type Message = {
  invoiceId: string;
  message: string;
  transactionId: string;
};

export const AppointmentSuccess = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [getAppointmentDetails, { isLoading, data: appointments }] =
    useGetPatientAppointmentDetailsMutation();
  const [
    getDoctorClinicsDetails,
    { isLoading: clinicDetailsLoading, data: doctorClinic },
  ] = useGetDoctorClinicDetailsMutation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get('id');
  const user = useSelector((state: RootState) => state.user.userDetails);
  const info = searchParams.get('info');

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  useEffect(() => {
    if (appointments?.length > 0 && !doctorClinic && !clinicDetailsLoading) {
      getDoctorClinicsDetails({
        id: appointments?.[0].doctorId,
        clinicId: appointments?.[0].clinicId,
      });
    }
  }, [
    clinicDetailsLoading,
    doctorClinic,
    appointments,
    getDoctorClinicsDetails,
  ]);

  useEffect(() => {
    if (id) {
      getAppointmentDetails(id);
    }
  }, [getAppointmentDetails, id]);

  const handleReturn = () => {
    navigate(PATHS.APPOINTMENTS);
  };

  const message = useMemo(() => {
    if (info && appointments?.[0]?.isBookingConfirmed === false) {
      const msgString = Buffer.from(info, 'base64').toString('utf-8');

      try {
        return JSON.parse(msgString) as Message;
      } catch (e) {
        return null;
      }
    }

    return null;
  }, [info, appointments]);

  const istStartDate = useMemo(() => {
    return moment(new Date(appointments?.[0]?.startTime));
  }, [appointments]);

  return (
    <div className="flex flex-col bg-white p-8 gap-4 text-brandingText">
      <header className="flex flex-col gap-4">
        <div className="flex w-full justify-between px-4">
          <div className="flex-1" />
          <div className="flex gap-4">
            <IconButton onClick={handlePrint}>
              <Print />
            </IconButton>
            <IconButton onClick={handleReturn}>
              <Close />
            </IconButton>
          </div>
        </div>
      </header>
      <div ref={ref} className="flex flex-col bg-white gap-4">
        <Loader isLoading={isLoading} />
        {!isLoading && (
          <>
            <div className="flex gap-4 items-center">
              <Typography variant="h4">
                {appointments?.[0]?.isBookingConfirmed
                  ? 'Appointment Confirmed!'
                  : 'Appointment not confirmed'}
              </Typography>
              {appointments?.[0]?.isBookingConfirmed ? (
                <CheckCircle color="success" sx={{ width: 50, height: 50 }} />
              ) : (
                <ReportGmailerrorred
                  color="warning"
                  sx={{ width: 50, height: 50 }}
                />
              )}
            </div>
            <div className="flex items-center flex-wrap justify-between w-full gap-4">
              <div className="flex gap-4 items-center">
                <Typography variant="h6">With: </Typography>
                <Avatar
                  src={appointments?.[0]?.doctor?.profilePicUrl}
                  sx={{ width: 50, height: 50 }}
                />

                <div className="flex gap-1">
                  <Typography variant="h6">Dr.</Typography>
                  <Typography variant="h6">
                    {appointments?.[0]?.doctor?.firstName}
                  </Typography>
                  <Typography variant="h6">
                    {appointments?.[0]?.doctor?.lastName}
                  </Typography>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <Typography variant="h6">For : </Typography>
                <Avatar
                  src={appointments?.[0]?.patient?.profilePicUrl}
                  sx={{ width: 50, height: 50 }}
                />
                <div className="flex flex-col gap-1">
                  <div className="flex gap-1">
                    <Typography variant="h6">
                      {appointments?.[0]?.patient?.firstName}
                    </Typography>
                    <Typography variant="h6">
                      {appointments?.[0]?.patient?.lastName}
                    </Typography>
                  </div>
                  {!!appointments?.[0]?.patient?.dob && (
                    <Typography>
                      {`Age ${calculateAge(
                        appointments?.[0]?.patient?.dob,
                      )} yrs`}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <div className="flex flex-col gap-2">
              <div className="flex gap-4">
                <Event />
                <Typography>
                  {istStartDate.format(APPOINTMENT_DISPLAY_DATE_FORMAT)}
                </Typography>
              </div>
              <div className="flex gap-4">
                <Schedule />
                <Typography>
                  {istStartDate.format(DISPLAY_TIME_FORMAT)}
                </Typography>
              </div>
              <Typography>
                {getClinicAddress(appointments?.[0]?.clinic)}
              </Typography>
            </div>
            {message && (
              <div className="flex flex-col w-full gap-5 bg-orange-200 p-4 rounded-md">
                <div className="flex gap-4 items-center">
                  <GppBad color="error" sx={{ width: 30, height: 30 }} />
                  <Typography variant="h6" fontWeight={600}>
                    {message.message}
                  </Typography>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="flex flex-col gap-2">
                    <Typography fontWeight={600}>Invoice Id</Typography>
                    <Typography>{message.invoiceId}</Typography>
                  </div>
                  <div className="flex flex-col gap-2">
                    <Typography fontWeight={600}>Transaction Id</Typography>
                    <Typography>{message.transactionId}</Typography>
                  </div>
                </div>
              </div>
            )}
            <Divider />
            {user?.balanceAmount || user?.spentAmount ? (
              <div className="flex gap-5">
                <div className="flex flex-col gap-2">
                  <Typography variant="h6">Balance Amount</Typography>
                  <Typography>₹ {user?.balanceAmount}/-</Typography>
                </div>
                <div className="flex flex-col gap-2">
                  <Typography variant="h6">Spent Amount</Typography>
                  <Typography>₹ {user?.spentAmount}/-</Typography>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
      <Divider />
      {!appointments?.[0]?.isBookingConfirmed && !message && (
        <div className="flex gap-2 flex-wrap">
          <Typography>{`Pay consultation charges of Rs. ${
            doctorClinic?.[0]?.consultationFeesCost ?? 0
          }  and confirm the appointment!`}</Typography>
          <Button
            type="submit"
            variant="contained"
            className="!normal-case"
            endIcon={<Check />}
          >
            {appointments?.[0]?.id
              ? 'Confirm and Update'
              : `Pay Rs. ${
                  doctorClinic?.[0]?.consultationFeesCost ?? 0
                } and Confirm Booking`}
          </Button>
        </div>
      )}
      <Divider />
      <div className="flex">
        <Button
          variant="contained"
          className="!normal-case"
          onClick={handleReturn}
        >
          View your Appointments
        </Button>
      </div>
    </div>
  );
};
