import React, { useState } from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
  CircularProgress,
  TextField,
} from '@mui/material';

export type AsyncSearchDropdownProps<T> = {
  options: T[];
  selectedOption: T | T[] | null;
  label: string;
  placeholder?: string;
  className?: string;
  multiple?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  touched?: boolean;
  error?: string | string[];
  disableCloseOnSelect?: boolean;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  getOptionLabel?: (option: T) => string;
  onSelect: (option: T | null, reason: AutocompleteChangeReason) => void;
  onOpen?: () => void;
  onChange?: (value: string, reason: AutocompleteInputChangeReason) => void;
};

export const AsyncSearchDropdown = <T extends {}>({
  options,
  multiple,
  selectedOption,
  className = '',
  label = 'Select...',
  disabled,
  isLoading,
  error,
  placeholder,
  disableCloseOnSelect,
  touched,
  renderOption,
  getOptionLabel,
  onSelect,
  onOpen,
  onChange,
}: AsyncSearchDropdownProps<T>) => {
  const [inputValue, setInputValue] = useState('');
  return (
    <Autocomplete
      id="search-autocomplete"
      className={`flex-grow-1 ${className} ${
        selectedOption ? '!bg-secondaryDark !text-white' : '!text-gray1'
      }`}
      multiple={multiple}
      value={selectedOption}
      options={options}
      color="primary"
      disabled={disabled}
      disableCloseOnSelect={disableCloseOnSelect}
      size="small"
      openOnFocus
      loading={isLoading}
      inputValue={inputValue}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <div className="d-flex flex-column">
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="info" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label={label}
            placeholder={placeholder}
          />
          {error && touched && (
            <span className="text-red-600 text-xs">
              {typeof error === 'object' ? error.join(', ') : error}
            </span>
          )}
        </div>
      )}
      onOpen={onOpen}
      onInputChange={(_, newInputValue, reason) => {
        setInputValue(newInputValue);
        onChange?.(newInputValue, reason);
      }}
      onChange={(_: any, newValue: any, reason: AutocompleteChangeReason) => {
        onSelect(newValue, reason);
      }}
    />
  );
};
