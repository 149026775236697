import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import jwt from 'jwt-decode';
import { Loader } from './components/Loader';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { AppLayout } from './layouts/AppLayout';
import { getToken, removeToken, setToken } from './localstorage/token';
import { useUserDetailsMutation } from './redux/services/user';
import { AppDispatch } from './redux/store';
import { setDecodedUserToken, setUserDetails } from './redux/userSlice';
import {
  isTokenExpired,
  redirectToLogin,
  redirectToRefreshToken,
} from './utils/common-utils';
import { APP_ROUTES, PATHS } from './routes';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const AuthRoute = () => {
  const [userDetails, { isLoading }] = useUserDetailsMutation();
  const dispatch = useDispatch<AppDispatch>();
  const token = new URLSearchParams(window.location.search).get('token');
  const navigate = useNavigate();
  const loc = useLocation();

  const navigateToCorrectRoute = useCallback(() => {
    const params = window.location.search.replace(`token=${token}`, '');
    if (!loc.pathname || loc.pathname === '/') {
      navigate(`${PATHS.HOME}${params !== '?' ? params : ''}`);
    } else {
      navigate(`${loc.pathname}${params !== '?' ? params : ''}`);
    }
  }, [loc.pathname, navigate, token]);

  useEffect(() => {
    if (token) {
      setToken(token);
      const decodedToken = jwt(token);

      dispatch(setDecodedUserToken(decodedToken));
      navigateToCorrectRoute();
    } else if (!loc.pathname || loc.pathname === '/') {
      navigate(PATHS.HOME);
    }
  }, [dispatch, loc.pathname, navigate, navigateToCorrectRoute, token]);

  const fetchUserDetails = useCallback(async () => {
    const res: any = await userDetails('');

    if (res.data) {
      const storageToken = getToken();

      dispatch(setUserDetails(res.data));
      dispatch(setDecodedUserToken(jwt(storageToken!)));
    } else {
      removeToken();
      redirectToLogin();
    }
  }, [dispatch, userDetails]);

  useEffect(() => {
    const storageToken = getToken();

    if (storageToken && isTokenExpired()) {
      redirectToRefreshToken();
    } else if (!storageToken) {
      redirectToLogin();
    } else {
      fetchUserDetails();
    }
  }, [fetchUserDetails]);

  return isLoading ? (
    <div className="flex w-screen h-screen items-center justify-center">
      <Loader isLoading />
    </div>
  ) : (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        {APP_ROUTES.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.element />}
          />
        ))}
        <Route path="*" element="404 not found" />
      </Route>
    </Routes>
  );
};
