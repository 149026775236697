import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../../localstorage/token";
import { API_PATH } from "./path";

export const REVIEW_PAGE_LIMIT = 5;
export const DEFAULT_DOCTOR_SLOT_DURATION = 30;
export const SEARCH_PAGE_LIMIT = 10;

export const doctorsApi = createApi({
  reducerPath: "doctorsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getDoctors: build.mutation({
      query: (params) => ({
        url: `/search/doctor`,
        params,
      }),
    }),
    getPatientDoctors: build.mutation({
      query: (params) => ({
        url: `/patient/doctors`,
        params,
      }),
    }),
    getDoctorDetails: build.mutation({
      query: (id) => ({
        url: `/doctors/${id}`,
        method: "GET",
      }),
    }),
    getDoctorProfile: build.mutation({
      query: (id) => ({
        url: `/customers/${id}/doctor-profile`,
        method: "GET",
      }),
    }),
    getDoctorsReviewCount: build.mutation({
      query: (id) => ({
        url: `/reviews/count?where={"doctorId":"${id}", "isActive": true, "isForDisplay": true}`,
      }),
    }),
    getDoctorsReviews: build.mutation({
      query: (params) => ({
        url: `/reviews/display?limit=${REVIEW_PAGE_LIMIT}&offset=${
          params.offset
        }${params.doctorId && `&doctorId=${params.doctorId}`}${
          params.clinicId && `&clinicId=${params.clinicId}`
        }`,
      }),
    }),
    createReview: build.mutation({
      query: (body) => {
        return {
          url: "/reviews",
          method: "POST",
          body,
        };
      },
    }),
    getDoctorClinics: build.mutation({
      query: (id) => ({
        url: `${API_PATH.customerController}/${id}/doctor-clinics?filter={"where": {"isCurrentlyWorking": true}, "include":[{"relation": "clinic", "scope": { "include":[{"relation": "stateMaster","include":[{"scope":{"fields":{"name":"true"}}}]},{"relation": "cityMaster","include":[{"scope":{"fields":{"name":"true"}}}]}] }}]}`,
        method: "GET",
      }),
    }),
    getDoctorClinicDetails: build.mutation({
      query: (params) => ({
        url: `${API_PATH.customerController}/${params.id}/doctor-clinics?filter={"where":{"isCurrentlyWorking": true, "clinicId":"${params.clinicId}"},"include":[{"relation": "clinic", "scope": { "include":[{"relation": "stateMaster","include":[{"scope":{"fields":{"name":"true"}}}]},{"relation": "cityMaster","include":[{"scope":{"fields":{"name":"true"}}}]}] }}]}`,
        method: "GET",
      }),
    }),
    getClinicDetails: build.mutation({
      query: (slug) => ({
        url: `/clinic/${slug}`,
      }),
    }),
    getDoctorSlots: build.mutation({
      query: (params) => ({
        url: `${API_PATH.doctor}/${params.doctorId}/slots`,
        method: "GET",
        params: {
          slotDate: params.slotDate,
          clinicId: params.clinicId,
          patientId: params.patientId,
        },
      }),
    }),
  }),
});

export const {
  useGetDoctorsReviewCountMutation,
  useGetDoctorsMutation,
  useGetDoctorDetailsMutation,
  useGetDoctorsReviewsMutation,
  useCreateReviewMutation,
  useGetDoctorClinicsMutation,
  useGetDoctorSlotsMutation,
  useGetDoctorClinicDetailsMutation,
  useGetPatientDoctorsMutation,
  useGetDoctorProfileMutation,
  useGetClinicDetailsMutation,
} = doctorsApi;
