import { useRef, useEffect, useMemo } from 'react';
import { Button, Divider, IconButton, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATHS } from '../../routes';
import { Check, Close, GppBad, Print } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import {
  useGetPatientAppointmentDetailsMutation,
  useInitiatePaymentMutation,
} from '../../redux/services/appointments';
import { useGetDoctorClinicDetailsMutation } from '../../redux/services/doctors';
import { Loader } from '../../components/Loader';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getClinicAddress } from '../../utils/doctor-utils';
import { useSnackbar } from 'notistack';
window.Buffer = window.Buffer || require('buffer').Buffer;

type Message = {
  invoiceId: string;
  message: string;
  transactionId: string;
};

export const AppointmentPaymentFailure = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const [getAppointmentDetails, { isLoading, data: appointments }] =
    useGetPatientAppointmentDetailsMutation();
  const [initiatePayment] = useInitiatePaymentMutation();
  const [
    getDoctorClinicsDetails,
    { isLoading: clinicDetailsLoading, data: doctorClinic },
  ] = useGetDoctorClinicDetailsMutation();
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');
  const info = searchParams.get('info');
  const user = useSelector((state: RootState) => state.user.userDetails);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (appointments?.length > 0 && !doctorClinic && !clinicDetailsLoading) {
      getDoctorClinicsDetails({
        id: appointments[0]?.doctorId,
        clinicId: appointments[0]?.clinicId,
      });
    }
  }, [
    clinicDetailsLoading,
    doctorClinic,
    appointments,
    getDoctorClinicsDetails,
  ]);

  useEffect(() => {
    if (id) {
      getAppointmentDetails(id);
    }
  }, [getAppointmentDetails, id]);

  const message = useMemo(() => {
    if (info) {
      const msgString = Buffer.from(info, 'base64').toString('utf-8');

      try {
        return JSON.parse(msgString) as Message;
      } catch (e) {
        return null;
      }
    }

    return null;
  }, [info]);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const handleReturn = () => {
    navigate(PATHS.APPOINTMENTS);
  };

  const handlePay = async () => {
    const pRes: any = await initiatePayment({
      body: {
        sUrl: `${window.location.origin}/appointments/success?id=${id}`,
        fUrl: `${window.location.origin}/appointments/failure?id=${id}`,
      },
      id,
    });

    if (pRes.data && pRes.data.status !== 0) {
      window.location.href = `${
        process.env.REACT_APP_EASEBUZZ_PAYMENT_URL ?? ''
      }/pay/${pRes.data.data}`;
    } else if (pRes.data['error_desc']) {
      enqueueSnackbar(pRes.data['error_desc'], { variant: 'error' });
    }
  };

  return (
    <div className="flex flex-col bg-white p-8 gap-4 rounded-md">
      <header className="flex flex-col gap-4">
        <div className="flex w-full justify-between px-4">
          <div className="flex-1" />
          <div className="flex gap-4">
            <IconButton onClick={handlePrint}>
              <Print />
            </IconButton>
            <IconButton onClick={handleReturn}>
              <Close />
            </IconButton>
          </div>
        </div>
      </header>
      <div ref={ref} className="flex flex-col p-4 gap-4">
        <Loader isLoading={isLoading} />

        {message ? (
          <div className="flex flex-col w-full gap-5 bg-red-200 p-4 rounded-md">
            <div className="flex gap-4 items-center">
              <GppBad color="error" sx={{ width: 30, height: 30 }} />
              <Typography variant="h5" color="error">
                {message.message}
              </Typography>
            </div>
            <div className="flex gap-4 items-center">
              <div className="flex flex-col gap-2">
                <Typography variant="h6">Transaction Id</Typography>
                <Typography>{message.invoiceId}</Typography>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex gap-4 items-center">
            <GppBad color="error" sx={{ width: 50, height: 50 }} />
            <Typography variant="h4">Transaction Failed!</Typography>
          </div>
        )}
        <Divider />
        {appointments?.[0] && (
          <div className="flex flex-col gap-4">
            <Typography variant="h6">{`Appointment with Dr. ${appointments?.[0]?.doctor?.firstName} ${appointments?.[0]?.doctor?.lastName} is not confirmed!`}</Typography>
            <div className="flex flex-col gap-2">
              <Typography variant="h6">Clinic Address</Typography>
              <Typography>
                {getClinicAddress(appointments?.[0]?.clinic)}
              </Typography>
            </div>
          </div>
        )}
        <Divider />
        <div className="flex gap-5">
          <div className="flex flex-col gap-2">
            <Typography variant="h6">Balance Amount</Typography>
            <Typography>₹ {user?.balanceAmount}/-</Typography>
          </div>
          <div className="flex flex-col gap-2">
            <Typography variant="h6">Spent Amount</Typography>
            <Typography>₹ {user?.spentAmount}/-</Typography>
          </div>
        </div>
        <Divider />
        <Divider />
        <div className="flex gap-2 flex-wrap items-center">
          <Typography>{`Pay consultation charges of Rs. ${
            doctorClinic?.[0]?.consultationFeesCost ?? 0
          }  and confirm the appointment!`}</Typography>
          <Button
            type="submit"
            variant="contained"
            className="!normal-case"
            endIcon={<Check />}
            onClick={handlePay}
          >
            {`Pay Rs. ${
              doctorClinic?.[0]?.consultationFeesCost ?? 0
            } and Confirm Booking`}
          </Button>
        </div>
        <div className="flex">
          <Button variant="contained" onClick={handleReturn}>
            Return
          </Button>
        </div>
      </div>
    </div>
  );
};
