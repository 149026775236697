import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import io from 'socket.io-client';
import { ArrowForwardIos, ArrowBackIosNew, Send } from '@mui/icons-material';
import {
  Card,
  Fab,
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
} from '@mui/material';
import Button from '@mui/material/Button/Button';
import CardContent from '@mui/material/CardContent/CardContent';
import { FormInput } from '../../components/FormInput';
import {
  useGetDoctorDetailsMutation,
  useGetPatientDoctorsMutation,
} from '../../redux/services/doctors';
import { Loader } from '../../components/Loader';
import { ICustomer } from '../../redux/types/customer';
import { stringAvatar } from '../../utils/common-utils';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import chatBackground from '../../assets/images/chat-background.jpg';
import {
  useCreateConversationMutation,
  useGetConversationsMutation,
} from '../../redux/services/conversations';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../constants';
import { IConversation } from '../../redux/types/conversations';

const socket = io(process.env.REACT_APP_WEBSOCKET_URL!, {
  reconnectionDelayMax: 10000,
  reconnectionAttempts: 3,
});

export const Chats = () => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [
    getPatientDoctors,
    { data: doctorData, isLoading: patientDoctorsLoading },
  ] = useGetPatientDoctorsMutation();
  const [getDoctorDetails, { data: doctor }] = useGetDoctorDetailsMutation();
  const [getConversations, { data: conversationData }] =
    useGetConversationsMutation();
  const [createConversation, { isLoading }] = useCreateConversationMutation();
  const user = useSelector((state: RootState) => state.user.userDetails);
  const [chats, setChats] = useState<IConversation[]>([]);
  const [params] = useSearchParams();
  const doctorId = params.get('doctorId');
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [chatText, setChatText] = useState('');
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (doctorId) {
      getDoctorDetails(doctorId);
    }
  }, [doctorId, getDoctorDetails]);

  useEffect(() => {
    if (ref.current && chats.length > 0) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chats]);

  useEffect(() => {
    if (user.id) {
      socket.emit('join', { userId: user.id });
    }
  }, [user.id]);

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    socket.on('message', (body) => {
      setChats((prevChats) => [...prevChats, body]);
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    });

    socket.io.on('error', (error) => {
      console.log(error);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('message');
      socket.io.off('error');
    };
  }, []);

  useEffect(() => {
    getPatientDoctors('');
  }, [getPatientDoctors]);

  useEffect(() => {
    if (conversationData?.data?.length > 0) {
      const data = [...conversationData.data];
      data.reverse();
      setChats(data);
    }
  }, [conversationData]);

  useEffect(() => {
    setChats([]);
    getConversations(doctorId);
    return () => {
      setChats([]);
    };
  }, [doctorId, getConversations]);

  const toggleMenuOpen = async () => setOpen(!open);

  const getListClickHandler = (doctorId: string) => () => {
    navigate(`${location.pathname}?doctorId=${doctorId}`);
    toggleMenuOpen();
  };

  const handleChangeChatText = (event: ChangeEvent<HTMLInputElement>) =>
    setChatText(event.target.value);

  const handleSend = async () => {
    const body = {
      from: user.id,
      to: doctorId!,
      text: chatText.trim(),
    } as IConversation;
    await createConversation(body);
    socket.emit('sendMessage', body);
    setChats([
      ...chats,
      {
        ...body,
        id: `${Date.now()}`,
        addedAt: moment().format(DATE_TIME_FORMAT),
      },
    ]);
    setChatText('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  const renderPeerList = () => {
    return (
      <div className="flex flex-col gap-1 h-full">
        <Card
          className={`flex-1 !transition-all !ease-out !duration-300 max-w-full ${
            open ? 'w-80' : 'w-0'
          }`}
        >
          <Loader isLoading={patientDoctorsLoading} />
          <List>
            {doctorData?.data?.map((doctor: ICustomer) => (
              <ListItem
                key={doctor.id}
                divider
                disableGutters
                onClick={getListClickHandler(doctor.id)}
              >
                <ListItemButton selected={doctor.id === doctorId}>
                  <ListItemAvatar>
                    <Avatar
                      alt="Doctor"
                      src={doctor.profilePicUrl}
                      {...stringAvatar(doctor.firstName, doctor.lastName)}
                    />
                  </ListItemAvatar>
                  <ListItemText>
                    {`Dr. ${doctor.firstName} ${doctor.lastName}`}
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <ArrowForwardIos color="primary" />
                  </ListItemSecondaryAction>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Card>
      </div>
    );
  };

  const renderPeerMessage = (message: string, date: string) => {
    return (
      <li className="flex w-11/12 md:w-2/4 gap-1 items-center">
        <Avatar
          alt="Doctor"
          src={doctor?.profilePicUrl}
          {...stringAvatar(doctor?.firstName ?? '-', doctor?.lastName ?? '-')}
        />
        <div className="flex flex-col text-brandingText flex-1 bg-white p-2 rounded-tr-[2rem] rounded-br-[2rem] odd:rounded-tl-[2rem] gap-1">
          <Typography variant="subtitle2">{`Dr ${doctor?.firstName} ${doctor?.lastName}`}</Typography>
          <Typography>{message}</Typography>
          <Typography variant="subtitle2">
            {moment(date).isValid()
              ? moment(date).format(DATE_TIME_FORMAT)
              : moment().format(DATE_TIME_FORMAT)}
          </Typography>
        </div>
      </li>
    );
  };

  const renderYourMessage = (message: string, date: string) => {
    return (
      <li className="flex flex-col gap-1 w-11/12 md:w-2/4 self-end p-2 px-8 text-brandingText bg-secondary rounded-tl-[2rem] rounded-bl-[2rem] odd:rounded-tr-[2rem]">
        <div className="flex flex-col">
          <Typography>{message}</Typography>
          <Typography className="w-full text-right" variant="subtitle2">
            {moment(date).isValid()
              ? moment(date).format(DATE_TIME_FORMAT)
              : moment().format(DATE_TIME_FORMAT)}
          </Typography>
        </div>
      </li>
    );
  };

  return (
    <div className="flex flex-col w-full h-full gap-4 overflow-auto p-4">
      <div className="flex w-full flex-1 md:gap-4 overflow-auto">
        {renderPeerList()}
        <Card
          hidden={!doctorId}
          className="flex flex-1 w-full h-full transition-all ease-out w-full flex-col"
        >
          <div className="flex px-4 pt-4 gap-2 justify-between items-center">
            {doctorId && (
              <>
                <Fab
                  size="small"
                  color="primary"
                  aria-label="menu"
                  onClick={toggleMenuOpen}
                >
                  {open ? (
                    <ArrowBackIosNew fontSize="small" className="text-white" />
                  ) : (
                    <ArrowForwardIos fontSize="small" className="text-white" />
                  )}
                </Fab>
                <Typography>{`Dr. ${doctor?.firstName ?? ''} ${
                  doctor?.lastName ?? ''
                }`}</Typography>
                <Avatar
                  alt="Doctor"
                  src={doctor?.profilePicUrl}
                  {...stringAvatar(
                    doctor?.firstName ?? '-',
                    doctor?.lastName ?? '-',
                  )}
                />
              </>
            )}
          </div>
          {doctorId ? (
            <CardContent className="flex flex-1 flex-col w-full gap-4 overflow-auto">
              <div
                className="flex flex-1 bg-slate-100 flex-1 w-full overflow-auto"
                style={{
                  backgroundImage: `url(${chatBackground})`,
                  backgroundSize: 'contain',
                }}
              >
                <ul className="flex flex-col list-none p-2 w-full h-full overflow-auto text-black gap-1">
                  {chats.map((conv: IConversation, i) => (
                    <React.Fragment key={`${conv.id} - ${i}`}>
                      {conv.from === doctorId
                        ? renderPeerMessage(conv.text, conv.addedAt || '')
                        : renderYourMessage(conv.text, conv.addedAt ?? '')}
                    </React.Fragment>
                  ))}
                  <div ref={ref} />
                </ul>
              </div>
              <div className="flex w-full bg-white gap-4">
                <FormInput
                  value={chatText}
                  disabled={isLoading}
                  label="Enter text"
                  placeholder="Enter text to send..."
                  onChange={handleChangeChatText}
                  onKeyDown={handleKeyDown}
                />
                <Button
                  variant="contained"
                  disabled={!isConnected || isLoading || chatText.length === 0}
                  endIcon={<Send fontSize="small" />}
                  onClick={handleSend}
                >
                  {isLoading ? (
                    <span className="flex gap-2">
                      <CircularProgress size="small" /> Sending...
                    </span>
                  ) : (
                    'Send'
                  )}
                </Button>
              </div>
            </CardContent>
          ) : (
            <div className="flex w-full h-full items-center justify-center">
              <Typography>
                Please select the conversation from the list on the left side
              </Typography>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};
