import { ArrowBackIosNewRounded } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  styled
} from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import {
  DEFAULT_DOCTOR_SLOT_DURATION,
  useGetDoctorClinicsMutation,
  useGetDoctorDetailsMutation,
  useGetDoctorSlotsMutation
} from "../../redux/services/doctors";
import { useSnackbar } from "notistack";
import {
  useCreatePatientAppointmentsMutation,
  useGetPatientAppointmentDetailsMutation,
  useInitiatePaymentMutation,
  useUpdatePatientAppointmentMutation
} from "../../redux/services/appointments";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Loader } from "../../components/Loader";
import { getClinicAddress } from "../../utils/doctor-utils";
import {
  attachTokenToUrl,
  isSubscriptionExpired,
  stringAvatar
} from "../../utils/common-utils";
import { AsyncSearchDropdown } from "../../components/AsyncSearchDropdown";
import { DoctorClinic, ScheduleTime } from "../../redux/types/doctor";
import { useFormik } from "formik";
import moment, { Moment } from "moment";
import {
  AppointmentStatus,
  AppointmentType,
  DoctorClinicRelation
} from "../../redux/enums";
import {
  Domain,
  SERVER_DATE_FORMAT,
  SLOT_API_TIME_FORMAT,
  TIME_FORMAT
} from "../../constants";
import { BuySubscriptionDialogTemplate } from "../../templates/BuySubscriptionDialogTemplate";
import { IAppointment } from "../../redux/types/appointment";

const StyledStaticDatePicker = styled(StaticDatePicker<Moment>)(
  ({ theme }) => ({
    "& .MuiPickersCalendarHeader-root": {
      border: "1px solid #707070",
      padding: "1.3rem",
      borderRadius: "2rem",
      marginTop: 0
    },
    "& .MuiButtonBase-root.MuiPickersDay-root": {
      "&.Mui-selected": {
        color: theme.palette.background.paper
      },
      "&:focus.Mui-selected": {
        backgroundColor: theme.palette.primary.main
      }
    }
  })
);

export const BookAppointment = () => {
  const [search] = useSearchParams();
  const appointmentId = search.get("appointmentId") ?? "";

  const [
    getAppointmentDetails,
    { data: appointments, isLoading: appointmentDetailsLoading }
  ] = useGetPatientAppointmentDetailsMutation();

  const familyMemberId = useSelector(
    (state: RootState) =>
      state.user.selectedFamilyMember?.id ?? state.user.userDetails?.id ?? null
  );
  const patientSubscription = useSelector(
    (state: RootState) => state.user.decodedUserToken?.subscriptions?.patient
  );
  const [displayNoSubscription, setDisplayNoSubscription] = useState(false);
  const [
    getDoctorSlots,
    { data: slotData, reset: resetSlots, isLoading: slotsLoading }
  ] = useGetDoctorSlotsMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [createAppointment, { isLoading: creatingAppointment }] =
    useCreatePatientAppointmentsMutation();
  const [updateAppointment, { isLoading: updatingAppointment }] =
    useUpdatePatientAppointmentMutation();
  const { id: doctorId } = useParams();
  const [getDoctorClinics, { data: clinics, isLoading: clinicsLoading }] =
    useGetDoctorClinicsMutation();
  const [
    getDoctorDetails,
    {
      isLoading: doctorDetailsLoading,
      data: doctorDetails,
      reset: resetDoctorDetails
    }
  ] = useGetDoctorDetailsMutation();
  const navigate = useNavigate();

  const [initiatePayment, { isLoading: paymentInitiating }] =
    useInitiatePaymentMutation();

  useEffect(() => {
    if (appointmentId) {
      getAppointmentDetails(appointmentId);
    }
  }, [appointmentId, getAppointmentDetails]);

  useEffect(() => {
    if (!clinics) {
      getDoctorClinics(doctorId);
    }
  }, [clinics, doctorId, getDoctorClinics]);

  useEffect(() => {
    if (!doctorDetails && !doctorDetailsLoading) {
      getDoctorDetails(doctorId);
    }
  }, [doctorDetails, doctorDetailsLoading, doctorId, getDoctorDetails]);

  const formik = useFormik({
    initialValues: {
      clinicId: "",
      date: null,
      selectedSlot: null
    },
    onSubmit: async (values) => {
      if (!patientSubscription || isSubscriptionExpired(patientSubscription)) {
        return setDisplayNoSubscription(true);
      }

      const selectedClinic = getSelectedDoctorClinic();
      const duration = selectedClinic.slotTime ?? DEFAULT_DOCTOR_SLOT_DURATION;

      const startTime = moment(
        `${moment(values.date).format(SERVER_DATE_FORMAT)} ${moment(
          values.selectedSlot,
          SLOT_API_TIME_FORMAT
        ).format(TIME_FORMAT)}`
      );
      const endTime = moment(startTime).add(duration, "minutes");
      const payload = {
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        appointmentType: AppointmentType.IN_CLINIC,
        patientId: appointments?.[0]?.patientId ?? familyMemberId,
        doctorId,
        clinicId: appointments?.[0]?.clinicId ?? values.clinicId
      };

      const res: any = appointmentId
        ? await updateAppointment({
            ...payload,
            id: appointmentId,
            appointmentStatus: AppointmentStatus.RE_SCHEDULED
          })
        : await createAppointment(payload);
      if (res.error) {
        enqueueSnackbar(
          res.error.data?.error?.message ?? "Something went wrong",
          {
            variant: "error"
          }
        );
      } else {
        if (
          (selectedClinic.consultationFeesCost > 0 && !appointmentId) ||
          (selectedClinic.consultationFeesCost > 0 &&
            appointmentId &&
            !appointments?.[0].isBookingConfirmed)
        ) {
          const pRes: any = await initiatePayment({
            body: {
              sUrl: `${window.location.origin}/appointments/success?id=${
                appointmentId || res.data.id
              }`,
              fUrl: `${window.location.origin}/appointments/failure?id=${
                appointmentId || res.data.id
              }`
            },
            id: appointmentId || res?.data?.id
          });

          if (pRes.data && pRes.data.status !== 0) {
            window.location.href = `${
              process.env.REACT_APP_EASEBUZZ_PAYMENT_URL ?? ""
            }/pay/${pRes.data.data}`;
          } else if (pRes?.data?.["error_desc"] || pRes.error?.data?.error) {
            enqueueSnackbar(
              pRes?.data?.["error_desc"] ||
                "Payment could not be initialized, Please try again later !",
              { variant: "error" }
            );
            navigate("/appointments");
          }
        } else {
          enqueueSnackbar(
            `Appointment has been ${
              appointments?.length > 0 ? "Rescheduled" : "Scheduled"
            } successfully!`,
            {
              variant: "success"
            }
          );
          navigate("/appointments");
        }
        resetDoctorDetails();
      }
    }
  });

  useEffect(() => {
    if (
      !appointmentId &&
      clinics?.length === 1 &&
      !formik.touched.clinicId &&
      !formik.values.clinicId
    ) {
      formik.setFieldValue("clinicId", clinics[0].clinicId);
      formik.setFieldTouched("clinicId", true);
    }
  }, [appointmentId, clinics, doctorId, formik]);

  useEffect(() => {
    if (appointments?.length > 0 && doctorId && !formik.values.clinicId) {
      const ap = appointments[0];
      const slotDate = moment(ap.startTime).format(SERVER_DATE_FORMAT);

      formik.setFieldValue("clinicId", ap.clinicId);
      formik.setFieldValue("date", ap.startTime);
      getDoctorSlots({
        doctorId,
        clinicId: ap.clinicId,
        slotDate,
        patientId: familyMemberId
      });
    }
  }, [familyMemberId, doctorId, appointments, formik, getDoctorSlots]);

  const handleSelectDoctorClinic = (item: DoctorClinic | null) => {
    formik.setFieldValue("clinicId", item?.clinicId ?? "");
    formik.setFieldValue("selectedSlot", null);
    formik.setFieldValue("date", null);
    resetSlots();
  };

  const getSelectedDoctorClinic = useCallback(() => {
    if (formik.values.clinicId && clinics?.length) {
      return (
        clinics?.find(
          (item: DoctorClinic) => item.clinicId === formik.values.clinicId
        ) ?? null
      );
    }

    return null;
  }, [formik.values.clinicId, clinics]);

  const handleClickBack = () => {
    navigate("/appointments");
  };

  const handleDateChange = (value: Moment | null) => {
    const slotDate = moment(value).format(SERVER_DATE_FORMAT);
    formik.setFieldValue("date", value);
    getDoctorSlots({
      doctorId,
      clinicId: formik.values.clinicId,
      slotDate,
      patientId: familyMemberId
    });
  };

  const getChipClickHandler = (slot: string) => () => {
    formik.setFieldValue("selectedSlot", slot);
  };

  const handleShouldDisableDate = (date: Moment) => {
    const clinic: DoctorClinic | null = getSelectedDoctorClinic();

    if (clinic) {
      const workingDays = (clinic.workingDays ?? {}) as Record<
        string,
        ScheduleTime[]
      >;
      const holidays =
        clinic.holidays?.map((h) => moment(h).format(SERVER_DATE_FORMAT)) ?? [];
      const formattedDate = date.format(SERVER_DATE_FORMAT);
      const isWorkingDay =
        workingDays[date.format("dddd").toLowerCase()]?.length > 0;
      const isBookingAppointmentWithConsultantForSameDay =
        isWorkingDay &&
        !holidays.includes(formattedDate) &&
        clinic.relation === DoctorClinicRelation.CONSULTANT &&
        date.isSame(moment(), "day");
      const isValidAppointmentDate =
        isWorkingDay &&
        !holidays.includes(formattedDate) &&
        date <= moment().add(15, "days");

      if (
        isValidAppointmentDate &&
        !isBookingAppointmentWithConsultantForSameDay
      ) {
        return false;
      }

      return true;
    }

    return true;
  };

  const handleCloseNoSubscriptionDialog = () => setDisplayNoSubscription(false);

  const handleBuySubscriptionClick = () => {
    window.location.replace(
      attachTokenToUrl(
        Domain.ACCOUNT,
        `/subscriptions/patient?callbackUrl=${window.location.origin}${window.location.pathname}${window.location.search}`
      )
    );
  };

  const slots = useMemo(() => {
    return slotData?.[0]?.slots?.filter((s: string) =>
      moment(formik.values.date).isSame(moment(), "date")
        ? moment(s, SLOT_API_TIME_FORMAT).isSameOrAfter(moment())
        : true
    );
  }, [formik.values.date, slotData]);

  const consultationFeesCost = useMemo(() => {
    const selectedClinic = getSelectedDoctorClinic();

    return selectedClinic?.consultationFeesCost ?? null;
  }, [getSelectedDoctorClinic]);

  const getButtonText = () => {
    if (appointments?.length > 0) {
      const ap = appointments[0] as IAppointment;

      if (!ap.isBookingConfirmed && consultationFeesCost > 0) {
        return "Pay & Reschedule";
      }

      return "Reschedule";
    }

    return consultationFeesCost > 0 ? "Pay & Book" : "Book";
  };

  return (
    <div className="flex flex-col w-full overflow-auto text-brandingText p-4">
      <Loader
        isLoading={
          doctorDetailsLoading ||
          paymentInitiating ||
          slotsLoading ||
          creatingAppointment ||
          updatingAppointment ||
          appointmentDetailsLoading
        }
      />
      <div className="flex justify-between w-full mb-4">
        <Button
          variant="text"
          color="primary"
          startIcon={<ArrowBackIosNewRounded />}
          onClick={handleClickBack}
        >
          Go Back
        </Button>
      </div>
      <form
        className="flex flex-col px-4 md:px-12 gap-2 md:gap-8"
        onSubmit={formik.handleSubmit}
      >
        <div className="flex w-full flex-col md:flex-row md:px-12 gap-2 md:gap-8 self-center">
          {doctorDetails && (
            <Chip
              label={`${doctorDetails?.firstName ?? ""} ${
                doctorDetails?.lastName ?? ""
              }`}
              className="flex-1"
              color="info"
            />
          )}
          <AsyncSearchDropdown
            selectedOption={getSelectedDoctorClinic()}
            options={(clinics ?? []) as DoctorClinic[]}
            className="flex-1"
            isLoading={clinicsLoading}
            getOptionLabel={(option) => option.clinic?.name ?? "-"}
            label=""
            disabled={appointments?.length > 0}
            placeholder="Choose Doctor Clinic..."
            renderOption={(params, option) => (
              <ListItem key={option.id} color="primary" {...params}>
                <ListItemAvatar>
                  <Avatar
                    alt="Clinic"
                    variant="circular"
                    src={option.clinic?.clinicLogoUrl}
                    {...stringAvatar(option.clinic!.name)}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <div className="flex flex-col w-full text-brandingText">
                    <Typography variant="button" fontWeight={600}>
                      {option.clinic?.name}
                    </Typography>
                    <div className="flex gap-1">
                      <Typography variant="caption">
                        {getClinicAddress(option.clinic!)}
                      </Typography>
                    </div>
                  </div>
                </ListItemText>
              </ListItem>
            )}
            onSelect={handleSelectDoctorClinic}
          />
        </div>
        <div className="flex w-full flex-col lg:flex-row gap-2 md:gap-8 md:shadow-md md:bg-white rounded-lg md:px-12 pt-10 self-center">
          <div className="flex flex-1 justify-center lg:justify-start">
            <StyledStaticDatePicker
              value={formik.values.date}
              showToolbar={false}
              disablePast
              className="p-4 md:p-0 bg-white"
              disabled={!formik.values.clinicId}
              disableHighlightToday
              renderInput={(params) => <TextField {...params} />}
              onChange={handleDateChange}
              shouldDisableDate={handleShouldDisableDate}
              componentsProps={{
                actionBar: {
                  actions: []
                }
              }}
            />
          </div>
          {formik.values.date && (
            <div className="flex w-full flex-col flex-1 gap-4 bg-white p-4 rounded-lg shadow-sm md:p-0 md:pb-4">
              <div className="flex p-2 border-[1px] rounded-[2rem] justify-center border-borderColor1">
                <Typography>
                  {moment(formik.values.date).format("DD MMMM, dddd")}
                </Typography>
              </div>
              {slots?.length > 0 ? (
                <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
                  {slotData?.[0]?.slots
                    ?.filter((s: string) =>
                      moment(formik.values.date).isSame(moment(), "date")
                        ? moment(s, SLOT_API_TIME_FORMAT).isSameOrAfter(
                            moment()
                          )
                        : true
                    )
                    .map((s: string) => (
                      <Chip
                        key={s}
                        label={moment(s, SLOT_API_TIME_FORMAT).format(
                          SLOT_API_TIME_FORMAT
                        )}
                        variant={
                          s === formik.values.selectedSlot
                            ? "filled"
                            : "outlined"
                        }
                        color="primary"
                        onClick={getChipClickHandler(s)}
                      />
                    ))}
                </div>
              ) : (
                !slotsLoading && (
                  <div className="flex w-full items-center justify-center flex-1">
                    <Typography fontWeight={600}>
                      No Slots Available.
                    </Typography>
                  </div>
                )
              )}

              <div className="flex justify-between items-center flex-1 items-end text-brandingText">
                {consultationFeesCost > 0 &&
                !appointments?.[0]?.isBookingConfirmed ? (
                  <Typography
                    fontWeight={600}
                  >{`Consultation Charges ₹${consultationFeesCost}/-`}</Typography>
                ) : (
                  <div />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disableElevation
                  disabled={
                    !formik.values.clinicId ||
                    !formik.values.date ||
                    !formik.values.selectedSlot
                  }
                >
                  {getButtonText()}
                </Button>
              </div>
            </div>
          )}
        </div>
      </form>
      <Dialog
        open={displayNoSubscription}
        onClose={handleCloseNoSubscriptionDialog}
      >
        <BuySubscriptionDialogTemplate
          onClose={handleCloseNoSubscriptionDialog}
          onConfirm={handleBuySubscriptionClick}
        />
      </Dialog>
    </div>
  );
};
