import { useCallback, useEffect, useState } from 'react';
import {
  PAGE_LIMIT,
  useGetAppointmentPaidReimbursementsMutation,
} from '../../../../redux/services/appointments';
import moment from 'moment';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Hidden,
  Stack,
  Typography,
} from '@mui/material';
import { DATE_FORMAT, SERVER_DATE_FORMAT } from '../../../../constants';
import { useAppSelector } from '../../../../redux/store';
import { DataGrid } from '@mui/x-data-grid';
import { stringAvatar } from '../../../../utils/common-utils';
import { AppointmentPaymentDetails } from '../../../../redux/types/appointment';
import { Loader } from '../../../../components/Loader';

export const PaidReimbursements = () => {
  const [page, setPage] = useState(0);
  const [getAppointmentReimbursements, { isLoading, data }] =
    useGetAppointmentPaidReimbursementsMutation();
  const patientId = useAppSelector((state) => {
    const { userDetails, selectedFamilyMember } = state.user;

    if (selectedFamilyMember) {
      return selectedFamilyMember.id;
    }

    return userDetails?.id ?? '';
  });

  const fetchAppointmentReimbursements = useCallback(
    (offset = 0) => {
      getAppointmentReimbursements({
        offset,
        today: moment().format(SERVER_DATE_FORMAT),
      });
    },
    [getAppointmentReimbursements],
  );

  useEffect(() => {
    if (patientId) {
      fetchAppointmentReimbursements();
    }
  }, [patientId, fetchAppointmentReimbursements]);

  const handlePageChange = (value: number) => {
    setPage(value);
    fetchAppointmentReimbursements(PAGE_LIMIT * value);
  };

  return (
    <div className="flex flex-col w-full h-full overflow-auto gap-2 relative bg-white">
      <Hidden smUp>
        <div className="flex flex-wrap flex-1 overflow-auto w-full gap-4 content-start">
          <Loader isLoading={isLoading} />
          {data?.data?.length > 0
            ? data.data.map((item: AppointmentPaymentDetails) => (
                <Card key={item.transactionId} className="w-full md:w-fit">
                  <CardHeader
                    avatar={
                      <Avatar
                        {...stringAvatar(
                          item.doctor?.firstName ?? '',
                          item.doctor?.lastName ?? '',
                        )}
                        src={item.doctor?.profilePicUrl ?? ''}
                      />
                    }
                    className="bg-primary text-white"
                    title={`Dr. ${item.doctor?.firstName ?? '-'} ${
                      item.doctor?.lastName ?? '-'
                    }`}
                    subheader={item.clinic?.name ?? '-'}
                    subheaderTypographyProps={{ color: 'white' }}
                  />
                  <CardContent>
                    <div className="flex gap-2 mt-2">
                      <Typography>Payment Received Date : </Typography>
                      <Typography>
                        {moment(item.paymentReceivedDate).format(DATE_FORMAT)}
                      </Typography>
                    </div>
                    <div className="flex gap-2 mt-2 mb-2">
                      <Typography>Bill Amount :</Typography>
                      <Typography>₹ {item.total}/-</Typography>
                    </div>
                    <Divider />
                    <div className="flex gap-2 mt-2 flex-wrap">
                      <Typography>Reimbursement amount :</Typography>
                      <Typography>
                        ₹ {item.reimbursementAmountToPatient ?? 0}/-
                      </Typography>
                    </div>
                    <div className="flex gap-2 mt-2 flex-wrap">
                      <Typography>Reimbursement Processing Fee :</Typography>
                      <Typography>
                        ₹ {item.reimbursementProcessingFee?.toFixed(2) ?? 0.0}
                        /-
                      </Typography>
                    </div>
                    <div className="flex gap-2 mt-2">
                      <Typography>Reimbursement Paid Date :</Typography>
                      <Typography>
                        {moment(item.reimbursementPaidDate).format(DATE_FORMAT)}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              ))
            : !isLoading && (
                <div className="flex w-full h-full flex-1 justify-center items-center">
                  <Typography>No data available for display</Typography>
                </div>
              )}
        </div>
      </Hidden>
      <Hidden smDown>
        <DataGrid
          rows={data?.data ?? []}
          columns={[
            {
              field: 'paymentReceivedDate',
              headerName: 'Payment Received Date',
              width: 180,
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(DATE_FORMAT) : '-',
            },
            {
              field: 'doctor',
              headerName: 'Doctor',
              width: 200,
              valueFormatter: (params) =>
                `Dr ${params.value.firstName} ${params.value.lastName}`,
            },
            {
              field: 'clinic',
              headerName: 'Clinic',
              width: 200,
              valueFormatter: (params) => params.value.name,
            },
            {
              field: 'total',
              headerName: 'Bill Amount',
              width: 150,
              align: 'right',
              headerAlign: 'right',
              valueFormatter: (params) => `₹ ${params.value?.toFixed(2)}/-`,
            },
            {
              field: 'reimbursementAmountToPatient',
              headerName: 'Reimbursement',
              width: 150,
              align: 'right',
              headerAlign: 'right',
              valueFormatter: (params) =>
                `₹ ${params.value?.toFixed(2) ?? 0}/-`,
            },
            {
              field: 'reimbursementProcessingFee',
              headerName: 'Processing fee',
              width: 150,
              align: 'right',
              headerAlign: 'right',
              valueFormatter: (params) =>
                `₹ ${params.value?.toFixed(2) ?? 0}/-`,
            },
            {
              field: 'reimbursementDateForPatient',
              headerName: 'Date of claim',
              width: 150,
              valueFormatter: (params) =>
                params.value ? moment(params.value).format(DATE_FORMAT) : '-',
            },
            {
              field: 'reimbursementPaid',
              headerName: 'Reimbursement Paid',
              width: 150,
              align: 'right',
              headerAlign: 'right',
              valueFormatter: (params) =>
                `₹ ${params.value?.toFixed(2) ?? 0}/-`,
            },
            {
              field: 'reimbursementPaidDate',
              headerName: 'Reimbursement Paid Date',
              width: 200,
              valueFormatter: (params) =>
                moment(params.value).format(DATE_FORMAT),
            },
          ]}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No reimbursement data available
              </Stack>
            ),
          }}
          rowCount={data?.count ?? 0}
          page={page}
          pageSize={PAGE_LIMIT}
          pagination
          rowsPerPageOptions={[]}
          loading={isLoading}
          onPageChange={handlePageChange}
        />
      </Hidden>
    </div>
  );
};
