export const TIME_FORMAT = "HH:mm:ss";
export const DISPLAY_TIME_FORMAT = "HH:mm A";
export const SLOT_API_TIME_FORMAT = "hh:mm A";
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm A";
export const APPOINTMENT_DISPLAY_DATE_FORMAT = "ddd DD MMM YYYY";
export const SERVER_DATE_FORMAT = "YYYY-MM-DD";
export const SERVER_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const PAYMENT_GATEWAY_UNAVAILABLE_MSG =
  "Payment Gateway under maintainance";
export const DOCTOR_IMAGE_URL =
  "https://idda-resources.s3.ap-south-1.amazonaws.com/other/doctor-icon.svg";

export enum Domain {
  WWW,
  ACCOUNT
}
