import React, { ChangeEvent } from 'react';
import {
  InputAdornment,
  InputBase,
  TextField,
  alpha,
  styled,
} from '@mui/material';

export type FormInputProps = {
  value: string | number;
  type?: string;
  error?: string;
  touched?: boolean;
  name?: string;
  label?: string;
  disabled?: boolean;
  multiline?: boolean;
  placeholder?: string;
  containerClass?: string;
  maxLength?: number;
  rows?: number;
  icon?: React.ReactElement;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
};

const DefaultInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: '35px',
    position: 'relative',
    backgroundColor: theme.palette.primary.light,
    fontSize: 14,
    fontWeight: 500,
    width: '100%',
    padding: '5px 12px',
    boxShadow: 'inset 0px 0px 13px #00000005',
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['background-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.light, 0.25)} 0 0 0 0.2rem`,
    },
  },
}));

export const FormInput: React.FC<FormInputProps> = ({
  value,
  name,
  label,
  placeholder,
  multiline,
  disabled,
  type,
  error,
  containerClass = '',
  touched,
  icon,
  maxLength,
  rows,
  onChange,
  onClick,
  onKeyDown,
}) => {
  return (
    <div className={`flex flex-col w-full ${containerClass}`}>
      <DefaultInput
        size="small"
        placeholder={placeholder}
        name={name}
        type={type}
        disabled={disabled}
        value={value}
        rows={rows}
        multiline={multiline}
        inputProps={{
          maxLength,
        }}
        startAdornment={
          icon && <InputAdornment position="start">{icon}</InputAdornment>
        }
        onChange={onChange}
        onClick={onClick}
        onKeyDown={onKeyDown}
      />
      {error && touched && (
        <span className="text-red-600 text-xs mt-1">{error}</span>
      )}
    </div>
  );
};
