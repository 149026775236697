import React, { createContext, useCallback, useRef } from 'react';
import { DialogManager } from './DialogManager';
import { DialogMethodProps, DialogProps } from './types';

export const DialogContext = createContext<DialogMethodProps | null>(null);

export const DialogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const ref = useRef<DialogMethodProps>();
  const displayDialog = useCallback(
    (dialogProps: DialogProps) => ref.current?.displayDialog(dialogProps),
    [],
  );
  const closeDialog = useCallback(() => ref.current?.closeDialog(), []);
  const updateDialog = useCallback((props: DialogProps) => ref.current?.updateDialog(props), []);

  return (
    <DialogContext.Provider value={{ displayDialog, updateDialog, closeDialog }}>
      {children}
      <DialogManager ref={ref} />
    </DialogContext.Provider>
  );
};
