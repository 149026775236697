import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import { usersApi } from './services/user';
import { locationApi } from './services/location-masters';
import { appointmentsApi } from './services/appointments';
import { doctorsApi } from './services/doctors';
import { conversationApi } from './services/conversations';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
  reducer: {
    user: userReducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [appointmentsApi.reducerPath]: appointmentsApi.reducer,
    [doctorsApi.reducerPath]: doctorsApi.reducer,
    [conversationApi.reducerPath]: conversationApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      usersApi.middleware,
      locationApi.middleware,
      appointmentsApi.middleware,
      doctorsApi.middleware,
      conversationApi.middleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
