import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
} from '@mui/material';
import { useUpdatePatientAppointmentMutation } from '../../redux/services/appointments';
import { useSnackbar } from 'notistack';
import { IAppointment } from '../../redux/types/appointment';
import { AppointmentStatus } from '../../redux/enums';

export type CancelAppointmentProps = {
  appointment: IAppointment;
  onSuccess: () => void;
  onClose: () => void;
};

export const CancelAppointment: React.FC<CancelAppointmentProps> = ({
  appointment,
  onSuccess,
  onClose,
}) => {
  const [cancelAppointment, { isLoading }] =
    useUpdatePatientAppointmentMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleCancelAppointment = async () => {
    const res: any = await cancelAppointment({
      id: appointment.id,
      clinicId: appointment.clinicId,
      doctorId: appointment.doctorId,
      patientId: appointment.patientId,
      startTime: appointment.startTime,
      endTime: appointment.endTime,
      appointmentStatus: AppointmentStatus.CANCELLED,
    });

    if (!res.error) {
      enqueueSnackbar('Appointment has been canceled successfully!', {
        variant: 'success',
      });
      onSuccess();
    } else {
      enqueueSnackbar(
        res.error.data?.error?.message ?? 'Something went wrong',
        {
          variant: 'error',
        },
      );
    }

    onClose();
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Cancel Appointment</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to cancel the appointment ? This action can not
          be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onClose}>
          No
        </Button>
        <Button
          disabled={isLoading}
          variant="contained"
          onClick={handleCancelAppointment}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
