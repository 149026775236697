import { useEffect, useState } from "react";
import { Chat } from "@mui/icons-material";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Avatar,
  TablePagination,
  Button,
  IconButton,
  Tooltip
} from "@mui/material";
import {
  PAGE_LIMIT,
  useGetPatientAppointmentsCountMutation,
  useGetPatientAppointmentsMutation,
  useInitiatePaymentMutation
} from "../../redux/services/appointments";
import { IAppointment } from "../../redux/types/appointment";
import { attachTokenToUrl, stringAvatar } from "../../utils/common-utils";
import moment from "moment";
import {
  APPOINTMENT_DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  Domain
} from "../../constants";
import { Loader } from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import { ViewAppointmentDetails } from "../../Drawers/ViewAppointmentDetails";
import noAppointmentsPlaceholder from "../../assets/images/no-appointment.png";
import { CancelAppointment } from "../../Dialogs/CancelAppointment";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { AppointmentStatus } from "../../redux/enums";
import { CARD_COLOR_MAPPING, STATUS_COLOR_MAPPING } from "./constants";
import { useDialog } from "../../providers/DialogProvider";
import { useGetDoctorClinicDetailsMutation } from "../../redux/services/doctors";
import { useSnackbar } from "notistack";
import { ICustomer } from "../../redux/types/customer";
import { IClinic } from "../../redux/types/clinic";
import { getClinicAddress } from "../../utils/doctor-utils";
import { CardActions } from "./CardActions";

export const Appointments = () => {
  const [page, setPage] = useState(0);
  const [getAppointmentCount, { data: countData }] =
    useGetPatientAppointmentsCountMutation();
  const [getPatientAppointments, { data, isLoading }] =
    useGetPatientAppointmentsMutation();
  const [getDoctorClinic, { isLoading: doctorClinicLoading }] =
    useGetDoctorClinicDetailsMutation();
  const [initiatePayment] = useInitiatePaymentMutation();
  const navigate = useNavigate();
  const [selectedAppointment, setSelectedAppointment] =
    useState<IAppointment | null>(null);
  const [viewAppointment, setViewAppointment] = useState(false);
  const [viewCancelAppointment, setViewCancelAppointment] = useState(false);
  const familyMemberId = useSelector(
    (state: RootState) =>
      state.user.selectedFamilyMember?.id ?? state.user.userDetails?.id ?? null
  );
  const { enqueueSnackbar } = useSnackbar();

  const { displayDialog } = useDialog();

  useEffect(() => {
    if (familyMemberId && !countData) {
      getAppointmentCount(familyMemberId);
    }
  }, [familyMemberId, countData, getAppointmentCount]);

  useEffect(() => {
    if (familyMemberId) {
      getPatientAppointments({ offset: 0, patientId: familyMemberId });
    }
  }, [familyMemberId, getPatientAppointments]);

  const handleCloseAppointmentDetails = () => {
    setSelectedAppointment(null);
    setViewAppointment(false);
  };

  const getViewAppointmentHandler = (appointment: IAppointment) => () => {
    setSelectedAppointment(appointment);
    setViewAppointment(true);
  };

  const getRescheduleAppointmentHandler = (appointment: IAppointment) => () => {
    navigate(
      `/doctor/${appointment?.doctorId}/book-appointment?appointmentId=${appointment?.id}`
    );
  };

  const getCancelAppointmentHandler = (appointment: IAppointment) => () => {
    setSelectedAppointment(appointment);
    setViewCancelAppointment(true);
  };

  const handleCloseCancelDialog = () => {
    setViewCancelAppointment(false);
    setSelectedAppointment(null);
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    value: number
  ) => {
    setPage(value);
    getPatientAppointments({
      offset: PAGE_LIMIT * value,
      patientId: familyMemberId
    });
  };

  const handleCancelSuccess = () => {
    getPatientAppointments({ offset: 0, patientId: familyMemberId });
    getAppointmentCount(familyMemberId);
  };

  const getDoctorNameClickHandler = (doctor?: ICustomer) => () => {
    if (doctor) {
      window.location.href = attachTokenToUrl(
        Domain.WWW,
        `/doctor/${doctor.slug || doctor.id}`
      );
    }
  };

  const getDoctorClinicClickHandler = (clinic?: IClinic) => () => {
    if (clinic) {
      window.location.href = attachTokenToUrl(
        Domain.WWW,
        `/clinic/${clinic?.slug || clinic?.id}`
      );
    }
  };

  const getPayAppointmentChargesClickHandler =
    (appointment: IAppointment) => async () => {
      const res: any = await getDoctorClinic({
        id: appointment.doctorId,
        clinicId: appointment.clinicId
      });

      if (res.data && res.data.length > 0) {
        displayDialog({
          title: `Consultation charges`,
          content: (
            <Typography>
              Pay consultation charges for
              {` Dr. ${appointment.doctor?.firstName} ${appointment.doctor?.lastName}`}
              <b>{` Rs. ${res.data[0].consultationFeesCost ?? 0}`}</b>
            </Typography>
          ),
          cancelBtnTxt: "Cancel",
          submitBtnTxt: "Pay",
          onAccept: async () => {
            const pRes: any = await initiatePayment({
              body: {
                sUrl: `${window.location.origin}/appointments/success?id=${appointment.id}`,
                fUrl: `${window.location.origin}/appointments/failure?id=${appointment.id}`
              },
              id: appointment.id
            });

            if (pRes.data && pRes.data.status !== 0) {
              window.location.href = `${
                process.env.REACT_APP_EASEBUZZ_PAYMENT_URL ?? ""
              }/pay/${pRes.data.data}`;
            } else if (pRes.data?.["error_desc"] || pRes.error) {
              enqueueSnackbar(
                pRes.data?.["error_desc"] ??
                  pRes.error?.data?.error?.message ??
                  "Something went wrong",
                {
                  variant: "error"
                }
              );
            }
          }
        });
      } else {
        enqueueSnackbar(
          "Unexpected error occurred! Please try again after some time."
        );
      }
    };

  const renderAppointmentDate = (appointment: IAppointment) => {
    const date = moment(new Date(appointment.startTime));

    if (date.isSame(moment(), "date")) {
      return (
        <div className="flex gap-1 items-center">
          <span className="w-2 h-2 rounded-full bg-green-600 animate-pulse"></span>
          <span className="text-green-600">
            {date.format(APPOINTMENT_DISPLAY_DATE_FORMAT)}
          </span>
          <Typography variant="caption" color="green" fontWeight={600}>
            ( Today )
          </Typography>
        </div>
      );
    }

    return date.format(APPOINTMENT_DISPLAY_DATE_FORMAT);
  };

  const getOpenChatClickHandler = (doctor: ICustomer) => () => {
    navigate(`/chats?doctorId=${doctor.id}`);
  };

  return (
    <div className="flex flex-col h-full gap-2 relative items-center">
      <Loader isLoading={isLoading || doctorClinicLoading} />
      <div className="flex w-full gap-4 justify-between">
        {countData?.count > PAGE_LIMIT ? (
          <TablePagination
            component="div"
            count={countData?.count}
            page={page}
            color="primary"
            rowsPerPage={PAGE_LIMIT}
            rowsPerPageOptions={[]}
            onPageChange={handlePageChange}
          />
        ) : (
          <div className="flex-1" />
        )}
      </div>
      {data ? (
        <div className="flex flex-wrap overflow-auto relative gap-4 w-full justify-center md:justify-start">
          {data?.map((appointment: IAppointment) => (
            <Card key={appointment.id} className="w-full md:w-80">
              <div
                className={`w-full h-1 ${
                  moment(appointment.startTime).isBefore(moment())
                    ? "!bg-slate-500"
                    : appointment.isBookingConfirmed
                    ? CARD_COLOR_MAPPING[appointment.appointmentStatus]
                    : CARD_COLOR_MAPPING["unpaid"]
                }`}
              />
              <CardHeader
                avatar={
                  <Avatar
                    src={appointment.doctor?.profilePicUrl}
                    {...stringAvatar(
                      appointment.doctor?.firstName ?? "",
                      appointment.doctor?.lastName ?? ""
                    )}
                    className="!cursor-pointer"
                    onClick={getDoctorNameClickHandler(appointment.doctor)}
                  />
                }
                title={
                  <span
                    className="cursor-pointer text-brandingText select-none font-semibold hover:text-footer"
                    onClick={getDoctorNameClickHandler(appointment.doctor)}
                  >
                    {`Dr. ${appointment.doctor?.firstName} ${appointment.doctor?.lastName}`}
                  </span>
                }
                subheader={
                  <div className="flex flex-col gap-2">
                    <span>{renderAppointmentDate(appointment)}</span>
                    <div className="flex items-center min-h-[50px]">
                      {appointment.isBookingConfirmed ||
                      appointment.appointmentStatus ===
                        AppointmentStatus.CANCELLED ||
                      moment().isAfter(moment(appointment.startTime)) ? (
                        <span
                          className={`rounded-full capitalize rounded-full w-fit px-2 py-1 text-xs font-semibold ${
                            appointment.isBookingConfirmed
                              ? STATUS_COLOR_MAPPING[
                                  appointment.appointmentStatus
                                ]
                              : STATUS_COLOR_MAPPING["unpaid"]
                          }`}
                        >
                          {appointment.appointmentStatus || ""}
                        </span>
                      ) : (
                        !moment().isAfter(moment(appointment.startTime)) &&
                        !appointment.isBookingConfirmed &&
                        [
                          AppointmentStatus.BOOKED,
                          AppointmentStatus.UNPAID,
                          AppointmentStatus.RE_SCHEDULED
                        ].includes(appointment.appointmentStatus) && (
                          <Button
                            size="small"
                            variant="contained"
                            className="!normal-case"
                            onClick={getPayAppointmentChargesClickHandler(
                              appointment
                            )}
                          >
                            Pay
                          </Button>
                        )
                      )}
                      {appointment.appointmentStatus !==
                        AppointmentStatus.CANCELLED && (
                        <CardActions
                          onlyDisplayView={
                            moment(appointment.startTime).isBefore(
                              moment(),
                              "day"
                            ) ||
                            moment(appointment.startTime).isSame(
                              moment(),
                              "day"
                            )
                          }
                          onView={getViewAppointmentHandler(appointment)}
                          onCancel={getCancelAppointmentHandler(appointment)}
                          onReschedule={getRescheduleAppointmentHandler(
                            appointment
                          )}
                        />
                      )}
                    </div>
                  </div>
                }
              />
              <Divider />
              <CardContent>
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <span
                      className="cursor-pointer flex flex-col gap-2 mt-1 text-brandingText  hover:!text-footer"
                      onClick={getDoctorClinicClickHandler(appointment.clinic)}
                    >
                      <Typography variant="subtitle2" fontWeight={600}>
                        {appointment.clinic?.name ?? "-"}
                      </Typography>
                      <Typography variant="caption">
                        {getClinicAddress(appointment.clinic!)}
                      </Typography>
                    </span>
                    <span className="flex items-center text-brandingText gap-2 mt-1">
                      <Typography variant="subtitle2" fontWeight={600}>
                        {`${moment(new Date(appointment.startTime)).format(
                          DISPLAY_TIME_FORMAT
                        )} - ${moment(new Date(appointment.endTime)).format(
                          DISPLAY_TIME_FORMAT
                        )}`}
                      </Typography>
                    </span>
                  </div>
                  {appointment.doctor?.doctorProfile?.isAvailableForChat && (
                    <div>
                      <Tooltip title="Chat with Doctor">
                        <IconButton
                          onClick={getOpenChatClickHandler(appointment.doctor)}
                        >
                          <Chat color="primary" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      ) : (
        !isLoading && (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <img
              className="w-28 h-28"
              src={noAppointmentsPlaceholder}
              alt="no doctors found"
            />
            <Typography className="text-center p-2" variant="h6">
              No appointment has been created yet. Please book an appointment to
              consult with a doctor.
            </Typography>
          </div>
        )
      )}
      {selectedAppointment && viewAppointment && (
        <ViewAppointmentDetails
          appointmentId={selectedAppointment.id!}
          doctorId={selectedAppointment.doctorId!}
          onClose={handleCloseAppointmentDetails}
        />
      )}
      {selectedAppointment && viewCancelAppointment && (
        <CancelAppointment
          appointment={selectedAppointment}
          onClose={handleCloseCancelDialog}
          onSuccess={handleCancelSuccess}
        />
      )}
    </div>
  );
};
