export enum IndustryType {
  DENTISTRY = 'dentistry',
  DERMATOLOGY = 'dermatology',
}

export enum CategoryType {
  Report = 'report',
  ProfilePic = 'profile_pic',
  ClinicPic = 'clinic_pic',
  Document = 'document',
}

export enum ServicesAllowed {
  GUEST = 'guest',
  PATIENT = 'patient',
  DOCTOR = 'doctor',
  CONSULTANT = 'consultant',
  CLINIC = 'clinic',
  SEO = 'seo',
  MANAGER = 'manager',
  ADMIN = 'admin',
}

export enum DoctorClinicRelation {
  OWNER = 'owner',
  RESIDENT = 'resident',
  CONSULTANT = 'consultant',
}

export enum AppliedFor {
  DOCTOR = 'doctor',
  CONSULTANT = 'consultant',
  CLINIC = 'clinic',
}

export enum AppointmentType {
  VIDEO = 'video',
  IN_CLINIC = 'in_clinic',
  AUDIO = 'audio',
}

export enum PaymentMode {
  CASH = 'cash',
  CHEQUE = 'cheque',
  ONLINE = 'online',
  UPI = 'upi',
  CARD = 'card',
  BANK_TRANSFER = 'bank transfer',
}

export enum AppointmentStatus {
  BOOKED = 'booked',
  CANCELLED = 'cancelled',
  RE_SCHEDULED = 'rescheduled',
  WALKED_IN = 'walked_in',
  NOT_TURNED_UP = `not_turned_up`,
  WAIT = 'wait',
  IN_PROGRESS = 'in_progress',
  TREATMENT_DONE = 'treatment_done',
  PAYMENT_DONE = 'payment_done',
  FINISH = 'finish',
  BLOCK_CALENDAR = 'block_calendar',
  UNPAID = 'unpaid',
}
