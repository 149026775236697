import { Domain } from "../constants";
import { PATHS } from "../routes";
import { attachTokenToUrl } from "../utils/common-utils";

export const MENU: ({ name: string } & (
  | { path: string }
  | { route: string }
))[] = [
  {
    name: "Home",
    path: attachTokenToUrl(Domain.WWW, `/`)
  },
  {
    name: "Search Doctors",
    path: attachTokenToUrl(Domain.WWW, `/search-doctors?search=&localityTown=`)
  },
  {
    name: "Our Story",
    path: attachTokenToUrl(Domain.WWW, `/our-story`)
  },
  {
    name: "Blogs",
    path: attachTokenToUrl(Domain.WWW, `/blogs`)
  },
  {
    name: "Subscriptions",
    path: attachTokenToUrl(Domain.WWW, `/subscriptions/patients`)
  },
  {
    name: "Software for Providers",
    path: attachTokenToUrl(Domain.WWW, `/subscriptions/doctors`)
  }
];

export const OTHER_TABS = [
  {
    name: "Contact Us",
    link: attachTokenToUrl(Domain.WWW, `/#contact-us`)
  },
  {
    name: "Help",
    link: attachTokenToUrl(Domain.WWW, `/help`)
  },
  {
    name: "Terms And Conditions",
    link: attachTokenToUrl(Domain.WWW, `/terms-and-conditions`)
  },
  {
    name: "Privacy Policy",
    link: attachTokenToUrl(Domain.WWW, `/privacy-policy`)
  }
];

export const LOGGED_IN_MENU = [
  {
    name: "Appointments",
    path: PATHS.APPOINTMENTS
  },
  {
    name: "Invoices",
    path: PATHS.INVOICES
  },
  {
    name: "Reimbursements",
    path: PATHS.REIMBURSEMENTS
  }
];
