import React from "react";
import { Avatar, Menu, Typography, Button } from "@mui/material";
import { removeToken } from "../../localstorage/token";
import { ICustomer } from "../../redux/types/customer";
import {
  attachTokenToUrl,
  redirectToLogout,
  stringAvatar
} from "../../utils/common-utils";
import { Domain } from "../../constants";

export type AccountMenuProps = {
  user: ICustomer;
};

export const AccountMenu: React.FC<AccountMenuProps> = ({ user }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    removeToken();
    redirectToLogout();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMenuItemClickHandler = (path: string) => () => {
    window.location.href = path;
  };

  return (
    <>
      <div className="flex no-wrap gap-2 cursor-pointer" onClick={handleClick}>
        <Avatar
          src={user.profilePicUrl ?? ""}
          {...stringAvatar(user.firstName ?? "", user.lastName ?? "")}
        />
        <div className="flex items-center">
          {user && (
            <Typography
              className="!text-gray1"
              variant="body2"
            >{`Hi! ${user.firstName}`}</Typography>
          )}
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        PaperProps={{
          className: "!backdrop-blur-[50px] !pb-[10px] !rounded-b-[40px]",
          sx: {
            boxShadow:
              "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);"
          }
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={handleClose}
        onClick={handleClose}
      >
        <div className="flex flex-col w-full items-start p-3 gap-3">
          <Button
            className="!text-patientToolbar"
            onClick={getMenuItemClickHandler(
              attachTokenToUrl(Domain.ACCOUNT, "/account")
            )}
          >
            My Account
          </Button>
          <Button
            className="!text-patientToolbar"
            onClick={getMenuItemClickHandler(
              attachTokenToUrl(Domain.ACCOUNT, "/account#my-subscriptions")
            )}
          >
            My Subscription
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className="!px-[15px] self-center"
            onClick={handleLogout}
          >
            Log out
          </Button>
        </div>
      </Menu>
    </>
  );
};
