import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

export type BuySubscriptionDialogTemplateProps = {
  onClose: () => void;
  onConfirm: () => void;
};

export const BuySubscriptionDialogTemplate: React.FC<
  BuySubscriptionDialogTemplateProps
> = ({ onClose, onConfirm }) => (
  <>
    <Box className="flex justify-between items-center">
      <DialogTitle className="flex gap-4 items-center" color="primary">
        Buy Subscription
      </DialogTitle>
      <IconButton className="!mr-2" onClick={onClose}>
        <Close color="inherit" />
      </IconButton>
    </Box>
    <DialogContent>
      <div className="flex flex-col w-full h-full items-center gap-8 p-8">
        <Typography variant="h5" className="!font-bold">
          You do not have any active subscription
        </Typography>
        <Typography>
          To Enjoy the benefit of having your own account medical history as
          well as partial reimbursement benefits, you need to subscribe under
          individual, family or corporate plan or else will count your profile
          as Free Subscription where you can book an appointment but can not
          avail any other benefits!
        </Typography>
        <Button variant="contained" onClick={onConfirm}>
          Click on this link to subscribe
        </Button>
      </div>
    </DialogContent>
  </>
);
