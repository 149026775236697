import { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setBuySubscriptionPopupDisplayed } from "../../redux/userSlice";
import { useLocation } from "react-router-dom";
import { BuySubscriptionDialogTemplate } from "../../templates/BuySubscriptionDialogTemplate";
import {
  attachTokenToUrl,
  isSubscriptionExpired
} from "../../utils/common-utils";
import { Domain } from "../../constants";

export const BuySubscription = () => {
  const [display, setDisplay] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const buySubscrptionDisplayed = useSelector(
    (state: RootState) => state.user.buySubscriptionPopupDisplayed
  );
  const user = useSelector((state: RootState) => state.user.userDetails);
  const userToken = useSelector(
    (state: RootState) => state.user.decodedUserToken
  );

  useEffect(() => {
    if (
      !buySubscrptionDisplayed &&
      user?.id &&
      user?.id === userToken.id &&
      (!userToken.subscriptions?.patient ||
        isSubscriptionExpired(userToken.subscriptions?.patient ?? ""))
    ) {
      setDisplay(true);
    }
  }, [buySubscrptionDisplayed, location.pathname, user, userToken]);

  const handleBuySubscriptionClick = () => {
    setDisplay(false);
    dispatch(setBuySubscriptionPopupDisplayed(true));
    window.location.href = attachTokenToUrl(
      Domain.ACCOUNT,
      `/subscriptions/patient?callbackUrl=${window.location.origin}${window.location.pathname}${window.location.search}`
    );
  };

  const handleClose = () => {
    setDisplay(false);
    dispatch(setBuySubscriptionPopupDisplayed(true));
  };

  return (
    <Dialog
      open={display}
      PaperProps={{ sx: { width: 600 } }}
      onClose={handleClose}
    >
      <BuySubscriptionDialogTemplate
        onClose={handleClose}
        onConfirm={handleBuySubscriptionClick}
      />
    </Dialog>
  );
};
