import { Home } from './pages/Home';
import { Account } from './pages/Account';
import { Appointments } from './pages/Appointments';
import { Chats } from './pages/Chats';
import { Invoices } from './pages/Invoices';
import { Subscriptions } from './pages/Subscriptions';
import { AppointmentSuccess } from './pages/Appointments/AppointmentSuccess';
import { AppointmentPaymentFailure } from './pages/Appointments/AppointmentPaymentFailure';
import { Reimbursement } from './pages/Reimbursement';
import { InvoicePaymentSuccess } from './pages/Invoices/components/InvoicePaymentSuccess';
import { InvoicePaymentFailure } from './pages/Invoices/components/InvoicePaymentFailure/InvoicePaymentFailure';
import { BookAppointment } from './pages/BookAppointment';

export const PATHS = {
  HOME: '/',
  DOCTOR_BOOK_APPOINTMENT: '/doctor/:id/book-appointment',
  APPOINTMENTS: '/appointments',
  APPOINTMENT_SUCCESS: '/appointments/success',
  APPOINTMENT_FAILURE: '/appointments/failure',
  DOCTOR_APPOINTMENTS: '/appointments/:doctorId',
  EDIT_DOCTOR_APPOINTMENTS: '/appointments/:doctorId/:appointmentId',
  ACCOUNT: '/account',
  CHATS: '/chats',
  INVOICES: '/invoices',
  INVOICES_SUCCESS: '/invoices/payment/success/:id',
  INVOICES_FAILURE: '/invoices/payment/failure/:id',
  SUBSCRIPTIONS: '/subscriptions',
  REIMBURSEMENTS: '/reimbursements',
};

export const APP_ROUTES = [
  {
    path: PATHS.HOME,
    element: Home,
  },
  {
    path: PATHS.APPOINTMENTS,
    element: Appointments,
  },
  {
    path: PATHS.DOCTOR_BOOK_APPOINTMENT,
    element: BookAppointment,
  },
  {
    path: PATHS.DOCTOR_APPOINTMENTS,
    element: Appointments,
  },
  {
    path: PATHS.EDIT_DOCTOR_APPOINTMENTS,
    element: Appointments,
  },
  {
    path: PATHS.ACCOUNT,
    element: Account,
  },
  {
    path: PATHS.APPOINTMENT_SUCCESS,
    element: AppointmentSuccess,
  },
  {
    path: PATHS.APPOINTMENT_FAILURE,
    element: AppointmentPaymentFailure,
  },
  {
    path: PATHS.CHATS,
    element: Chats,
  },
  {
    path: PATHS.INVOICES,
    element: Invoices,
  },
  {
    path: PATHS.INVOICES_SUCCESS,
    element: InvoicePaymentSuccess,
  },
  {
    path: PATHS.INVOICES_FAILURE,
    element: InvoicePaymentFailure,
  },
  {
    path: PATHS.SUBSCRIPTIONS,
    element: Subscriptions,
  },
  {
    path: PATHS.REIMBURSEMENTS,
    element: Reimbursement,
  },
];
