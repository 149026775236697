import moment from "moment";
import { getToken, getTokenDate } from "../localstorage/token";
import { Domain } from "../constants";

export const redirectToLogin = () => {
  window.location.href = `${process.env.REACT_APP_ACCOUNT_BASE_URL}/login?callbackUrl=${window.location.origin}${window.location.pathname}${window.location.search}`;
};

export const redirectToLogout = () => {
  window.location.href = `${process.env.REACT_APP_ACCOUNT_BASE_URL}/logout?callbackUrl=${window.location.origin}${window.location.pathname}${window.location.search}`;
};

export const redirectToRefreshToken = () => {
  window.location.href = `${process.env.REACT_APP_ACCOUNT_BASE_URL}/refresh-token?callbackUrl=${window.location.origin}${window.location.pathname}${window.location.search}`;
};

export function stringToColor(val: string) {
  const string = val.trim();
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

export function stringAvatar(firstName: string, lastName = "") {
  return {
    sx: {
      bgcolor: stringToColor(`${firstName} ${lastName}`)
    },
    children: `${firstName?.[0]?.toUpperCase() ?? ""}${
      lastName?.[0]?.toUpperCase() ?? ""
    }`
  };
}

export function isTokenExpired() {
  const tokenDate = getTokenDate();

  if (!tokenDate) {
    return true;
  }

  return tokenDate && moment(tokenDate).isBefore(moment(), "day");
}

export function isSubscriptionExpired(date: string) {
  return date && moment(date).isBefore(moment());
}

export const CapitalizeString = (str: string): string => {
  if (str) {
    let result = str.charAt(0).toUpperCase() + str.slice(1);
    return result;
  }
  return "";
};

export function attachTokenToUrl(domain: Domain, path: `/${string}`) {
  const token = getToken();

  let url = "";

  switch (domain) {
    case Domain.WWW: {
      url = `${process.env.REACT_APP_WEBSITE_BASE_URL}${path}`;
      break;
    }
    case Domain.ACCOUNT: {
      url = `${process.env.REACT_APP_ACCOUNT_BASE_URL}${path}`;
      break;
    }
  }

  if (token) {
    const url2 = new URL(url);
    url2.searchParams.set("token", token);

    return url2.toString();
  } else {
    return url;
  }
}
