import React, { useRef, useEffect } from 'react';
import {
  Typography,
  IconButton,
  Divider,
  Drawer,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
} from '@mui/material';
import moment from 'moment';
import { Attachment, Close, Print } from '@mui/icons-material';
import {
  AppointmentActualProcedure,
  AppointmentClinicalNote,
  AppointmentClinicalReport,
  AppointmentPrescribedMedicine,
  AppointmentTreatmentOffered,
} from '../../redux/types/appointment';
import { calculateAge } from './utils';
import { colors } from '../../themes/colors';
import { useReactToPrint } from 'react-to-print';
import { useGetPatientAppointmentDetailsMutation } from '../../redux/services/appointments';
import { Loader } from '../../components/Loader';
import { APPOINTMENT_DISPLAY_DATE_FORMAT } from '../../constants';
import { useGetDoctorProfileMutation } from '../../redux/services/doctors';
import IDDALogo from '../../assets/images/logo.png';

export type ViewAppointmentDetailsProps = {
  appointmentId: string;
  doctorId: string;
  onClose: () => void;
};

export const ViewAppointmentDetails: React.FC<ViewAppointmentDetailsProps> = ({
  appointmentId,
  doctorId,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [getAppointmentDetails, { data: appointment, isLoading }] =
    useGetPatientAppointmentDetailsMutation();
  const [getDoctorProfile, { data: doctorProfile }] =
    useGetDoctorProfileMutation();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  useEffect(() => {
    getAppointmentDetails(appointmentId);
  }, [appointmentId, getAppointmentDetails]);

  useEffect(() => {
    if (doctorId) {
      getDoctorProfile(doctorId);
    }
  }, [doctorId, getDoctorProfile]);

  return (
    <Drawer
      open
      onClose={onClose}
      anchor="right"
      PaperProps={{ className: '!w-[95vw] md:w-2/4' }}
    >
      {isLoading && (
        <div className="w-full h-full flex justify-center items-center relative">
          <Loader isLoading={isLoading} />
        </div>
      )}
      {!isLoading && (
        <div className="flex flex-col w-full h-full">
          <header className="flex flex-col gap-4">
            <div className="flex w-full justify-between px-4">
              <div className="flex-1" />
              <div className="flex gap-4">
                <IconButton onClick={handlePrint}>
                  <Print />
                </IconButton>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </div>
            </div>
          </header>
          <div
            ref={ref}
            className="flex flex-col w-full gap-4 overflow-auto px-12 py-6"
          >
            {doctorProfile?.letterHeadHeaderHTML && (
              <div
                dangerouslySetInnerHTML={{
                  __html: doctorProfile?.letterHeadHeaderHTML as string,
                }}
              />
            )}
            <Divider />
            <div className="px-4 py-2 bg-slate-100 flex justify-between items-center">
              <span>{moment().format(APPOINTMENT_DISPLAY_DATE_FORMAT)}</span>
              <div className="flex gap-2">
                <Typography>Appointment Date: </Typography>
                <Typography>
                  {moment(new Date(appointment?.[0].startTime)).format(
                    APPOINTMENT_DISPLAY_DATE_FORMAT,
                  )}
                </Typography>
              </div>
            </div>
            <div className="flex justify-between px-4">
              <div className="flex gap-4">
                <Typography variant="subtitle2">{`${
                  appointment?.[0].patient?.title
                    ? `${appointment?.[0].patient?.title} `
                    : ''
                }${appointment?.[0].patient?.firstName} ${
                  appointment?.[0].patient?.lastName
                }`}</Typography>
                <Typography variant="subtitle2">
                  {`${appointment?.[0].patient?.gender ?? ''} ${calculateAge(
                    appointment?.[0].patient?.dob ?? '',
                  )} yrs`}
                </Typography>
              </div>
              <Typography variant="subtitle2">{`ID: ${appointment?.[0].patient?.uniqueId}`}</Typography>
            </div>
            <Divider />
            {appointment?.[0].appointmentClinicalNotes?.length > 0 && (
              <>
                <div className="px-4 py-2 bg-slate-100">
                  <Typography>Clinical Notes:</Typography>
                </div>
                <Table
                  size="small"
                  sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="bg-slate-100"
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Investigation
                      </TableCell>
                      <TableCell
                        className="bg-slate-100"
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Observation
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appointment?.[0].appointmentClinicalNotes?.map(
                      (clinicalNote: AppointmentClinicalNote) => (
                        <React.Fragment key={clinicalNote.id}>
                          <TableRow>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {clinicalNote.investigation}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {clinicalNote.observation}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                borderBottom: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <div className="flex gap-4 text-sm">
                                <Typography color="gray" variant="subtitle2">
                                  Noted by
                                </Typography>
                                <Typography variant="subtitle2">{`${
                                  clinicalNote.doctor?.firstName ?? ''
                                } ${
                                  clinicalNote.doctor?.lastName ?? '-'
                                }`}</Typography>
                              </div>
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: `1px solid ${colors.gray['gray30']}`,
                              }}
                            />
                          </TableRow>
                        </React.Fragment>
                      ),
                    )}
                  </TableBody>
                </Table>
              </>
            )}
            {appointment?.[0].appointmentTreatmentOffereds?.length > 0 && (
              <>
                <div className="px-4 py-2 bg-slate-100">
                  <Typography>Treatment Planned:</Typography>
                </div>
                <Table
                  size="small"
                  aria-label="a dense Table"
                  sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                >
                  <TableHead style={{ textAlign: 'left' }}>
                    <TableRow>
                      <TableCell
                        className="bg-slate-100"
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Treatment Plan
                      </TableCell>
                      <TableCell
                        className="bg-slate-100"
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Cost
                      </TableCell>
                      <TableCell
                        className="bg-slate-100"
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        className="bg-slate-100"
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Planned By
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appointment?.[0].appointmentTreatmentOffereds?.map(
                      (treatment: AppointmentTreatmentOffered) => (
                        <React.Fragment key={treatment.id}>
                          <TableRow>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {treatment.treatmentDetails || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {treatment.cost}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {treatment.total}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="subtitle2">{`${treatment.doctor?.firstName} ${treatment.doctor?.lastName}`}</Typography>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ),
                    )}
                  </TableBody>
                </Table>
              </>
            )}
            {appointment?.[0].appointmentActualProcedures?.length > 0 && (
              <>
                <div className="px-4 py-2 bg-slate-100">
                  <Typography>Actual Procedure:</Typography>
                </div>
                <Table size="small">
                  <TableHead style={{ textAlign: 'left' }}>
                    <TableRow>
                      <TableCell
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Procedure
                      </TableCell>
                      <TableCell
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Cost
                      </TableCell>
                      <TableCell
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Through
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appointment?.[0].appointmentActualProcedures?.map(
                      (procedure: AppointmentActualProcedure) => (
                        <React.Fragment key={procedure.id}>
                          <TableRow
                            style={{
                              borderTop: `1px solid ${colors.gray['gray30']}`,
                            }}
                          >
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {procedure.procedureDetails || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {procedure.cost}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {procedure.total}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {procedure.procedureStatus}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {`${procedure.doctor?.firstName} ${procedure.doctor?.lastName}`}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ),
                    )}
                  </TableBody>
                </Table>
              </>
            )}
            {appointment?.[0].appointmentPrescribedMedicines?.length > 0 && (
              <>
                <div className="px-4 py-2 bg-slate-100">
                  <Typography>Prescribed Medicines:</Typography>
                </div>
                <Table size="small">
                  <TableHead style={{ textAlign: 'left' }}>
                    <TableRow>
                      <TableCell
                        className="bg-slate-100"
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Drug Name
                      </TableCell>
                      <TableCell
                        className="bg-slate-100"
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Intake
                      </TableCell>
                      <TableCell
                        className="bg-slate-100"
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Strength
                      </TableCell>
                      <TableCell
                        className="bg-slate-100"
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Duration
                      </TableCell>
                      <TableCell
                        className="bg-slate-100"
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        When to take
                      </TableCell>
                      <TableCell
                        className="bg-slate-100"
                        sx={{ border: `1px solid ${colors.gray['gray30']}` }}
                      >
                        Prescribed By
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appointment?.[0].appointmentPrescribedMedicines?.map(
                      (medicine: AppointmentPrescribedMedicine) => (
                        <React.Fragment key={medicine.id}>
                          <TableRow>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {medicine.drugName}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {medicine.intake}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {medicine.drugStrength}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {medicine.duration}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="caption">
                                {medicine.prePostMeal}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${colors.gray['gray30']}`,
                              }}
                            >
                              <Typography variant="subtitle2">{`${medicine.doctor?.firstName} ${medicine.doctor?.lastName}`}</Typography>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ),
                    )}
                  </TableBody>
                </Table>
              </>
            )}
            {appointment?.[0].appointmentClinicalReports?.length > 0 && (
              <>
                <div className="flex gap-4 px-4 py-2 bg-slate-100">
                  <Attachment />
                  <Typography>Clinical Reports:</Typography>
                </div>
                <div className="flex gap-2">
                  {appointment?.[0].appointmentClinicalReports?.map(
                    (report: AppointmentClinicalReport) => (
                      <a href={report.reportUrl}>
                        <span className="p-4 rounded-md text-sm bg-slate-100">
                          {report.reportName}
                        </span>
                      </a>
                    ),
                  ) || (
                    <Typography variant="caption" className="p-2">
                      No clinical reports available
                    </Typography>
                  )}
                </div>
              </>
            )}
            {doctorProfile?.letterHeadFooterHTML && (
              <>
                <Divider />
                <div
                  dangerouslySetInnerHTML={{
                    __html: doctorProfile?.letterHeadFooterHTML as string,
                  }}
                />
              </>
            )}
            <Divider />
            <div className="flex items-center justify-center gap-2 p-1">
              <Typography fontStyle="italic" variant="caption">
                Powered by <b>Indian Dental Derma Assurance</b>
              </Typography>
              <img
                className="w-5 h-6"
                src={IDDALogo}
                alt="Indian Dental Derma Assurance"
              />
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
};
