export const STATUS_COLOR_MAPPING = {
  unpaid: 'bg-rose-200 text-rose-600',
  booked: 'bg-indigo-200 text-indigo-600',
  cancelled: 'bg-red-200 text-red-600',
  rescheduled: 'bg-sky-200 text-sky-600',
  walked_in: 'bg-lime-200 text-lime-600',
  not_turned_up: 'bg-slate-400 text-slate-800',
  wait: 'bg-yellow-200 text-yellow-600',
  in_progress: 'bg-orange-200 text-orange-600',
  treatment_done: 'bg-teal-200 text-teal-600',
  payment_done: 'bg-blue-200 text-blue-600',
  finish: 'bg-green-200 text-green-600',
  block_calendar: '',
};

export const CARD_COLOR_MAPPING = {
  booked: 'bg-indigo-600',
  cancelled: 'bg-red-600',
  rescheduled: 'bg-sky-600',
  walked_in: 'bg-lime-600',
  not_turned_up: 'bg-slate-500',
  wait: 'bg-yellow-600',
  in_progress: 'bg-orange-600',
  treatment_done: 'bg-teal-600',
  payment_done: 'bg-blue-600',
  block_calendar: '',
  finish: 'bg-green-600',
  unpaid: 'bg-rose-600',
};
