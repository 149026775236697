import { useCallback, useEffect, useState } from 'react';
import {
  PAGE_LIMIT,
  useGetAppointmentActualProceduresByProcedureIdsMutation,
  useGetAppointmentInvoicesCountMutation,
  useGetAppointmentInvoicesMutation,
  useInitiateInvoicePaymentMutation,
} from '../../../../redux/services/appointments';
import { Loader } from '../../../../components/Loader';
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  TablePagination,
  Typography,
} from '@mui/material';
import { AppointmentPaymentDetails } from '../../../../redux/types/appointment';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { stringAvatar } from '../../../../utils/common-utils';
import { InvoiceDetails } from '../../../../Drawers/InvoiceDetails';
import { useDialog } from '../../../../providers/DialogProvider';
import { Stack } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';

export const PendingPayments = () => {
  const [page, setPage] = useState(0);
  const [invoiceHtml, setInvoiceHtml] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [getAppointmentInvoices, { isLoading, data }] =
    useGetAppointmentInvoicesMutation();
  const [getAppointmentInvoicesCount, { data: countData }] =
    useGetAppointmentInvoicesCountMutation();
  const { displayDialog } = useDialog();
  const [
    getAppointmentActualProcedures,
    { data: procedureData, isLoading: proceduresLoading },
  ] = useGetAppointmentActualProceduresByProcedureIdsMutation();
  const [initiateInvoicePayment, { isLoading: initiatingInvoicePayment }] =
    useInitiateInvoicePaymentMutation();
  const patientId = useSelector((state: RootState) => {
    const { userDetails, selectedFamilyMember } = state.user;

    if (selectedFamilyMember) {
      return selectedFamilyMember.id;
    }

    return userDetails?.id ?? '';
  });

  const fetchAppointmentInvoices = useCallback(
    (offset = 0) => {
      getAppointmentInvoices({ offset, paymentReceived: false, patientId });
    },
    [patientId, getAppointmentInvoices],
  );

  useEffect(() => {
    if (patientId) {
      fetchAppointmentInvoices();
      getAppointmentInvoicesCount({ patientId, paymentReceived: false });
    }
  }, [patientId, fetchAppointmentInvoices, getAppointmentInvoicesCount]);

  const renderProcedureDetailsTable = useCallback(() => {
    return (
      <div className="block h-[300px] min-w-[250px] lg:min-w-[400px]">
        <DataGrid
          rows={procedureData || []}
          columns={[
            {
              field: 'procedureDate',
              headerName: 'Procedure Date',
              width: 120,
              valueFormatter: ({ value }) => moment(value).format(DATE_FORMAT),
            },
            {
              field: 'doctor',
              headerName: 'Doctor',
              width: 150,
              valueGetter: (params) =>
                `${params.value?.firstName ?? '-'} ${
                  params.value?.lastName ?? '-'
                }`,
            },
            {
              field: 'clinic',
              headerName: 'Clinic',
              width: 150,
              valueGetter: (params) => `${params.value?.name ?? '-'}`,
            },
            {
              field: 'procedureDetails',
              headerName: 'Procedure Details',
              width: 150,
            },
            {
              field: 'total',
              headerName: 'Total',
              valueGetter: (params) => `${params.value}/-`,
            },
            {
              field: 'pending',
              headerName: 'Pending',
              valueGetter: ({ row }) => `${row.total - row.paymentReceived}/-`,
            },
          ]}
          loading={proceduresLoading}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Procedure details not available.
              </Stack>
            ),
          }}
          rowsPerPageOptions={[]}
        />
      </div>
    );
  }, [procedureData, proceduresLoading]);

  useEffect(() => {
    if (procedureData) {
      displayDialog({
        title: 'Procedure Details',
        content: renderProcedureDetailsTable(),
      });
    }
  }, [
    displayDialog,
    procedureData,
    proceduresLoading,
    renderProcedureDetailsTable,
  ]);

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    value: number,
  ) => {
    setPage(value);
    fetchAppointmentInvoices(PAGE_LIMIT * value);
  };

  const handleCloseinvoice = () => {
    setInvoiceHtml('');
  };

  const getInvoiceClickHandler = (invoiceHtml?: string) => () => {
    setInvoiceHtml(
      invoiceHtml ||
        '<p>No information available yet. Please visit this section again.</p>',
    );
  };

  const getPayClickHandler = (item: AppointmentPaymentDetails) => async () => {
    const pRes: any = await initiateInvoicePayment({
      body: {
        sUrl: `${window.location.origin}/invoices/payment/success/${item.id}`,
        fUrl: `${window.location.origin}/invoices/payment/failure/${item.id}`,
      },
      id: item.id,
    });

    if (pRes.data && pRes.data.status !== 0) {
      window.location.href = `${
        process.env.REACT_APP_EASEBUZZ_PAYMENT_URL ?? ''
      }/pay/${pRes.data.data}`;
    } else if (pRes.data?.['error_desc'] || pRes.error) {
      enqueueSnackbar(
        pRes.data?.['error_desc'] ??
          pRes.error?.data?.error?.message ??
          'Something went wrong',
        { variant: 'error' },
      );
    }
  };

  const getViewProcedureDetails =
    (item: AppointmentPaymentDetails) => async () => {
      await getAppointmentActualProcedures({
        patientId: item.patientId,
        procedureIds:
          item.actualProcedureIds?.map((id) => `"${id}"`)?.join(',') ?? [],
      });
    };

  return (
    <div className="flex flex-col md:flex-row  w-full h-full overflow-auto gap-2">
      <div className="flex flex-col gap-2 relative md:flex-[1.1]">
        <Loader
          isLoading={isLoading || proceduresLoading || initiatingInvoicePayment}
        />
        <div className="flex flex-wrap flex-1 overflow-auto w-full gap-4 content-start p-2">
          {data?.length > 0 ? (
            data.map((item: AppointmentPaymentDetails) => (
              <Card key={item.id} className="w-full md:w-72">
                <CardHeader
                  avatar={
                    <Avatar
                      {...stringAvatar(
                        item.doctor?.firstName ?? '',
                        item.doctor?.lastName ?? '',
                      )}
                      src={item.doctor?.profilePicUrl ?? ''}
                    />
                  }
                  className="bg-primary text-white"
                  title={`Dr. ${item.doctor?.firstName ?? '-'} ${
                    item.doctor?.lastName ?? '-'
                  }`}
                  subheader={item.clinic?.name ?? '-'}
                  subheaderTypographyProps={{ color: 'white' }}
                />
                <CardContent>
                  <div className="flex gap-2 mb-2">
                    <Typography fontSize={14}>Patient Name: </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                    >{`${item.patient?.firstName} ${item.patient?.lastName}`}</Typography>
                  </div>
                  <Divider />
                  <div className="flex gap-2 mt-2">
                    <Typography fontSize={14}>Invoice Date : </Typography>
                    <Typography fontSize={14} fontWeight={600}>
                      {item.paymentDate
                        ? moment(item.paymentDate).format(DATE_FORMAT)
                        : '-'}
                    </Typography>
                  </div>
                  {!item.onlineByIDDAPaymentReceived ? (
                    <div className="flex gap-2 mt-2">
                      <Typography color="error" fontSize={14}>
                        Amount Pending :
                      </Typography>
                      <Typography
                        color="error"
                        fontSize={14}
                        fontWeight={600}
                        align="right"
                      >
                        ₹ {item.amount}/-
                      </Typography>
                    </div>
                  ) : (
                    <div className="flex mt-2">
                      <Typography fontSize={14}>
                        Waiting for doctor to acknowledge the payment.
                      </Typography>
                    </div>
                  )}
                  <div className="flex gap-2 items-center mt-4">
                    {!item.onlineByIDDAPaymentReceived && (
                      <Button
                        variant="contained"
                        className="!normal-case"
                        onClick={getPayClickHandler(item)}
                      >
                        Pay
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      className="!normal-case"
                      onClick={getViewProcedureDetails(item)}
                    >
                      View Procedure Details
                    </Button>
                  </div>
                </CardContent>
                {item.invoiceHtml ? (
                  <CardActionArea
                    color="info"
                    className="!p-2 text-primary"
                    onClick={getInvoiceClickHandler(item.invoiceHtml)}
                  >
                    <Typography variant="button" fontWeight={600}>
                      View Invoice
                    </Typography>
                  </CardActionArea>
                ) : null}
              </Card>
            ))
          ) : (
            <div className="flex w-full h-full flex-1 justify-center items-center">
              <Typography>No Pending Payments</Typography>
            </div>
          )}
        </div>
        <div className="flex w-full gap-4 justify-between">
          {countData?.count > PAGE_LIMIT ? (
            <TablePagination
              component="div"
              count={countData.count}
              page={page}
              color="primary"
              rowsPerPage={PAGE_LIMIT}
              rowsPerPageOptions={[]}
              onPageChange={handlePageChange}
            />
          ) : (
            <div className="flex-1" />
          )}
        </div>
      </div>
      {invoiceHtml && (
        <InvoiceDetails
          invoiceHtml={invoiceHtml}
          onClose={handleCloseinvoice}
        />
      )}
    </div>
  );
};
