import moment from 'moment';

export const calculateAge = (dob: string) => {
  if (dob) {
    const diff = moment().diff(moment(dob).format(''), 'years', false);

    return diff;
  }

  return 0;
};
