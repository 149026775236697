import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../localstorage/token';

export const REVIEW_PAGE_LIMIT = 5;
export const DEFAULT_DOCTOR_SLOT_DURATION = 30;

export const conversationApi = createApi({
  reducerPath: 'conversationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getConversations: build.mutation({
      query: (peerId) => ({
        url: `/conversations?peerId=${peerId}`,
      }),
    }),
    createConversation: build.mutation({
      query: (body) => ({
        url: `/conversations`,
        body,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetConversationsMutation, useCreateConversationMutation } =
  conversationApi;
