import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../localstorage/token';
import { API_PATH } from './path';

export const usersApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    userDetails: build.mutation({
      query: () => ({
        url: `${API_PATH.userDetails}?filter={"include":[{"relation":"stateMaster","scope":{"where":{"isActive":"true"},"fields":{"id": true,"name": true}}},{"relation":"cityMaster","scope":{"where":{"isActive":"true"},"fields":{"id": true,"name": true}}},{"relation":"locationMaster","scope":{"where":{"isActive":"true"},"fields":{"id": true,"name": true}}}]}`,
        method: 'GET',
      }),
    }),
    uploadMedia: build.mutation({
      query: (params) => ({
        url: `${API_PATH.mediaUpload}?categoryType=${params.categoryType}`,
        method: 'POST',
        body: params.body,
      }),
    }),
    getUserSubscriptions: build.mutation({
      query: (id) => ({
        url: `${
          API_PATH.subscriptions
        }?filter={"where":{"and":[{"customerId":"${id}"},{"paymentReceived":"true"},{"isForPatient":"true"},{"validTill":{"gte":"${new Date().toISOString()}"}}]},"include":[{"relation":"packageMaster","where":{"isActive":"true"}}]}`,
        method: 'GET',
      }),
    }),
    getFamilyMembers: build.mutation({
      query: () => ({
        url: `${API_PATH.familyMembers}`,
        method: 'GET',
      }),
    }),
    logout: build.mutation({
      query: () => ({
        url: API_PATH.logout,
        method: 'DELETE',
      }),
    }),
    getServices: build.mutation({
      query: () => ({
        url: `${API_PATH.serviceMasters}?filter={"order":"name","where":{"isActive": "true"}}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useUserDetailsMutation,
  useUploadMediaMutation,
  useGetUserSubscriptionsMutation,
  useLogoutMutation,
  useGetFamilyMembersMutation,
  useGetServicesMutation,
} = usersApi;
