import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { colors } from './colors';
import { palette } from './palette';

export const defaultTheme = responsiveFontSizes(
  createTheme({
    palette,
    typography: {
      fontFamily: 'Helvetica',
    },
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: colors.primary,
              color: '#fff',
              '&:hover': {
                backgroundColor: colors.primary,
                color: '#fff',
              },
              '.MuiSvgIcon-root': {
                color: '#fff',
              },
            },
            '&:hover': {
              backgroundColor: colors.primaryLight,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '1.5rem',
            fontWeight: 600,
            textTransform: 'none',
            whiteSpace: 'nowrap',
          },
          contained: {
            color: 'white',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            fontSize: '0.8rem',
            padding: '0.2rem 1.5rem',
          },
          filled: {
            color: 'white',
          },
          label: {
            overflow: 'visible',
            lineHeight: 2,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            borderRadius: '2rem',
            color: 'inherit',
            border: '3px solid #70C1EA',
            WebkitTextFillColor: `inherit !important`,
            '& fieldset': {
              border: 0,
            },
            '& .Mui-disabled': {
              WebkitTextFillColor: `${colors.gray.gray05} !important`,
            },
            '& .MuiOutlinedInput-root': {
              color: 'inherit',
            },
          },
          inputRoot: {
            borderRadius: '2rem',
            padding: '0.1rem 1.5rem !important',
            fontSize: '0.8rem',
            fontWeight: 600,
            background: 'transparent',
          },
          input: {
            textAlign: 'center',
          },
        },
      },
    },
  }),
);
