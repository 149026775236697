import React, { useRef } from 'react';
import { IconButton, Drawer } from '@mui/material';
import { Close, Print } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';

export type InvoiceDetailsProps = {
  invoiceHtml: string;
  onClose: () => void;
};

export const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({
  invoiceHtml,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  return (
    <Drawer
      open
      onClose={onClose}
      anchor="right"
      PaperProps={{ className: '!w-[95vw] md:w-3/4' }}
    >
      <header className="flex flex-col gap-4 p-2">
        <div className="flex w-full justify-between px-4">
          <div className="flex-1" />
          <div className="flex gap-4">
            <IconButton onClick={handlePrint}>
              <Print />
            </IconButton>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        </div>
      </header>
      <div
        ref={ref}
        style={{ padding: 28 }}
        className="flex flex-col w-full gap-4 overflow-auto"
        dangerouslySetInnerHTML={{ __html: invoiceHtml }}
      />
    </Drawer>
  );
};
